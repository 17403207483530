import Popup from 'modules/common/Popup';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import popupIllustration from 'theme/assets/images/popup.svg';
import {actionButton, details} from './styles.scss';

const DoYouWantToReferFriendsPopup = ({handleClose}) => {
  return (
    <Popup exitPopup={handleClose}>
      <div className="w-50-ns w-80-m w-100 center">
        <img
          className="w-100"
          src={popupIllustration}
          alt="wait for a moment"
        />
      </div>
      <div className={details}>
        <h2>
          <FormattedMessage id="popup_referrals_title" />
        </h2>
        <p>
          <FormattedMessage id="popup_referrals_description" />
        </p>
      </div>
      <a
        href="/account/referrals/invite"
        target="_blank"
        rel="noopener noreferrer"
        className={`${actionButton} bg-blue bg-animate hover-bg-dark-blue w-100 white bn pointer dib`}
        onClick={handleClose}
      >
        <FormattedMessage id="popup_referrals_cta" />
      </a>
    </Popup>
  );
};

DoYouWantToReferFriendsPopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default DoYouWantToReferFriendsPopup;
