import compose from 'core/libs/helpers/compose.js';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import withLocation from '../hoc/withLocation';

class LocationRecorder extends Component {
  UNSAFE_componentWillMount() {
    // Scroll to the top when we init the app
    const {dispatch, location} = this.props;
    const {href, origin} = location;
    const completePath = href.replace(origin, '');
    dispatch({type: 'INIT_LOCATION', location: completePath});
  }

  UNSAFE_componentWillReceiveProps({location}) {
    if (location.pathname !== this.props.location.pathname) {
      // Scroll to the top at each route change
      const {dispatch} = this.props;
      const {href, origin} = this.props.location;
      const completePath = href.replace(origin, '');
      dispatch({type: 'CHANGE_LOCATION', location: completePath});
    }
  }

  render() {
    return null;
  }
}

LocationRecorder.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};

const enhance = compose(connect(), withLocation);

export default enhance(LocationRecorder);
