const notificationsByPage = (
  state = {data: {}, status: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_NOTIFICATIONS_SUCCESS': {
      const last_seen_notifications =
        state.last_seen_notifications &&
        state.last_seen_notifications >
          action.response.data.last_seen_notifications
          ? state.last_seen_notifications
          : action.response.data.last_seen_notifications;
      return {
        ...state,
        data: action.response.data,
        last_seen_notifications,
        status: 'fetched',
      };
    }
    case 'GET_NOTIFICATIONS_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    case 'UPDATE_USER_LAST_SEEN_DATE_REQUEST':
      return {
        ...state,
        last_seen_notifications: action.now,
      };
    default:
      return state;
  }
};

const notifications = (state = {}, action) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS_REQUEST':
    case 'GET_NOTIFICATIONS_SUCCESS':
    case 'GET_NOTIFICATIONS_FAILURE':
      return {
        ...state,
        [action.page]: notificationsByPage(state[action.page], action),
      };
    case 'UPDATE_USER_LAST_SEEN_DATE_REQUEST':
      return {
        ...state,
        1: notificationsByPage(state[1], action),
      };
    default:
      return state;
  }
};

export default notifications;
