import React from 'react';
import {FormattedMessage} from 'react-intl';

export default {
  header: {
    navMenu: [
      {
        name: 'tracks',
        path: '/tracks',
        text: <FormattedMessage id="tracks" />,
      },
      {
        name: 'community',
        path: 'https://community.barmej.com/',
        text: <FormattedMessage id="community" />,
        isExternal: true,
      },
      {
        name: 'leaderboard',
        path: '/leaderboard',
        text: <FormattedMessage id="leaderboard_title" />,
      },
    ],
    userMenu: [
      {
        text: <FormattedMessage id="account_settings" />,
        path: '/account/edit',
      },
      {
        text: <FormattedMessage id="referral_discounts" />,
        path: '/account/referrals',
        hideForPartner: true,
      },
      {
        text: <FormattedMessage id="billing" />,
        path: '/account/billing',
        hideForPartner: true,
      },
      {
        text: <FormattedMessage id="certificates" />,
        path: '/account/certificates',
      },
      // {
      //   text: <FormattedMessage id="apply_instructor" />,
      //   path: 'https://www.barmej.com/apply/instructor',
      //   displayAnonymous: true,
      //   isExternal: true,
      //   hideForPartner: true,
      // },
    ],
  },

  footer: {
    menu: [
      {
        name: 'terms',
        url: 'https://www.barmej.com/الشروط-و-الأحكام/القانون',
        text: <FormattedMessage id="termsfooter" />,
        isExternal: true,
      },
      {
        name: 'terms of registration',
        url: 'https://code.kw/terms',
        text: <FormattedMessage id="terms_of_registration" />,
        isExternal: true,
        hideForBarmej: true,
      },
      {
        name: 'privacy',
        url: 'https://www.barmej.com/سياسة-الخصوصية/القانون',
        text: <FormattedMessage id="privacy" />,
        isExternal: true,
      },
      {
        name: 'blog',
        url: 'https://blog.barmej.com/',
        text: <FormattedMessage id="barmej_blog" />,
        isExternal: true,
      },
      {
        name: 'about',
        url: 'https://www.barmej.com/عن-برمج/الصحافة',
        text: <FormattedMessage id="about" />,
        isExternal: true,
      },
    ],
    socials: [
      {
        name: 'facebook',
        url: 'https://www.facebook.com/barmej',
      },
      {
        name: 'twitter',
        url: 'https://twitter.com/barmej',
      },
      {
        name: 'instagram',
        url: 'https://instagram.com/barmej',
      },
      {
        name: 'github',
        url: 'https://www.github.com/barmej',
      },
    ],
  },

  accountUserMenu: [
    {
      url: '/account/edit',
      text: <FormattedMessage id="edit_account" />,
    },
    {
      url: '/account/password',
      text: <FormattedMessage id="change_password" />,
      hideForPartner: true,
    },
    {
      url: '/account/referrals',
      text: <FormattedMessage id="referral_discounts" />,
      hideForPartner: true,
    },
    {
      url: '/account/billing',
      text: <FormattedMessage id="billing" />,
      hideForPartner: true,
    },
    {
      url: '/account/settings',
      text: <FormattedMessage id="settings" />,
    },
  ],
};
