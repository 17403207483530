const selectedPlan = (state = {selectedPlan: null, paymentInfo: null, isTrial: null}, action) => {
  switch (action.type) {
  case 'RESET_DATA':
      return {
        ...state,
        selectedPlan: null,
        paymentInfo: null,
        isTrial: null
      };

  case 'SAVE_DATA':
        return {
          ...state,
          selectedPlan: action.payload.selectedPlan,
          paymentInfo: action.payload.paymentInfo,
        };
        
  case 'SAVE_TRIAL_DATA':
    return {
      ...state,
      isTrial: action.payload.isTrial,
    };

  default:
    return state;
  }

};

export default selectedPlan;
