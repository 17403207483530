import React from 'react';

class StartOver extends React.Component {
  static defaultProps = {
    width: '36px',
    height: '30px',
    color: '#5C9CE4',
  };

  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="2 15 36 5">
        <path
          fill={this.props.color}
          d="M24.6,11.36V9.7H23v1.66H16.53V9.7h-1.6v1.66H13V13h1.89v3.46H13V18h1.89v3.46H13v1.6h1.89v3.46H13v1.6h1.89v1.7h1.6v-1.7H23v1.7h1.6v-1.7h2.08v-1.6H24.6V23.08h2.08v-1.6H24.6V18h2.08v-1.6H24.6V13h2.08v-1.6ZM23,23.08v3.46H16.53V23.08Zm-6.46-1.6V18H23v3.46Zm0-5.06V13H23v3.46Z"
        />
      </svg>
    );
  }
}

export default StartOver;
