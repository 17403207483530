import React, {Component} from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import EarnPoint from 'modules/common/Svg/EarnPoint';
import Tooltip from 'modules/common/Tooltip';
import {mv} from './styles.scss';

class PointsSystemDiscount extends Component {
  render() {
    const {availableRedeemPoints, dividedBy} = this.props;
    const dollarsEarned = availableRedeemPoints / dividedBy;
    return (
      <Tooltip
        large
        isPointSystemDiscount
        message={
          <p className="black">
            <FormattedMessage id="points_system_discount_tooltip" />
          </p>
        }
      >
        <Link
          className={`${mv} flex items-center justify-center bg-white ph2 pv1 br2 blue f6 mh4`}
          to="/degrees"
        >
          <p className="ml1">${dollarsEarned}</p>=
          <div className="mh2">
            <EarnPoint width="33px" height="33px" color="#69b1f4" />
          </div>
          <p>
            {availableRedeemPoints} <FormattedMessage id="points_max" />
          </p>
        </Link>
      </Tooltip>
    );
  }
}

PointsSystemDiscount.propTypes = {
  availableRedeemPoints: PropTypes.number.isRequired,
  dividedBy: PropTypes.string.isRequired,
};

export default PointsSystemDiscount;
