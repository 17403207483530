import {compose, lifecycle, mapProps} from 'recompose';
import {connect} from 'react-redux';
import {getNotifications, updateLastSeenDate} from './actions';

const mapStateToProps = (state) => ({
  notifications: state.notifications[1] || {
    data: {},
    status: 'not fetched',
  },
  dateJoined: state.user.profile.data.date_joined,
  slug: state.user.profile.data.slug,
});
const mapDispatchToProps = {
  dGetNotifications: getNotifications,
  dUpdateLastSeenDate: updateLastSeenDate,
};

const withNotifications = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const {dGetNotifications} = this.props;
      dGetNotifications();
    },
  }),
  mapProps(
    ({
      dateJoined,
      dGetNotifications,
      notifications,
      dUpdateLastSeenDate,
      slug,
    }) => {
      let notificationCount = 0;
      let resultsData = [];
      if (notifications.status === 'fetched') {
        const {
          last_seen_notifications,
          data: {results},
        } = notifications;
        const lastSeenNotifications = last_seen_notifications || dateJoined;
        results.forEach(({created_at}) => {
          if (created_at > lastSeenNotifications) {
            notificationCount += 1;
          }
        });
        resultsData = results.filter(({created_at}) => created_at > dateJoined);
      }
      return {
        getNotifications: dGetNotifications,
        notificationCount,
        notificationsData: notifications,
        updateLastSeenDate: dUpdateLastSeenDate,
        slug,
        resultsData,
      };
    },
  ),
);

export default withNotifications;
