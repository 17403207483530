import Announcement from 'modules/common/Svg/Announcement';
import BadgeEarned from 'modules/common/Svg/BadgeEarned';
import Certificate from 'modules/common/Svg/Certificate';
import Course from 'modules/common/Svg/Course';
import LeaderBoard from 'modules/common/Svg/LeaderBoard';
import Project from 'modules/common/Svg/Project';
import Referrals from 'modules/common/Svg/Referrals';
import Reminder from 'modules/common/Svg/Reminder';
import Track from 'modules/common/Svg/Track';
import Warning from 'modules/common/Svg/Warning';
import PropTypes from 'prop-types';
import React from 'react';
import {notifIcon} from './styles.scss';

const IconAnnouncement = ({announcementType, color}) => {
  switch (announcementType) {
    case 'leaderboard':
      return <LeaderBoard />;
    case 'new course':
      return <Course />;
    case 'new track':
      return <Track />;
    case 'general':
      return <Announcement color={color} />;
    default:
      return null;
  }
};
IconAnnouncement.propTypes = {
  announcementType: PropTypes.string,
  color: PropTypes.string,
};

const IconNotification = ({notificationType}) => {
  switch (notificationType) {
    case 'school-track-completed':
    case 'school-degree-completed':
    case 'school-course-completed':
      return <BadgeEarned />;
    case 'school-order-your-certificate':
    case 'school-order-your-certificate-for-degree':
      return <Certificate />;
    case 'referral-after-course-completed':
    case 'refer-your-friends':
      return <Referrals />;
    case 'leaderboard-dropoff-alert':
      return <Warning />;
    case 'degree-project-submission-new':
    case 'track-project-submission-new':
    case 'degree-project-submission-in_review':
    case 'track-project-submission-in_review':
    case 'degree-project-submission-passed':
    case 'track-project-submission-passed':
      return <Project />;
    case 'degree-project-submission-failed':
    case 'track-project-submission-failed':
      return <Project color="#e50000" />;
    case 'bootcamp-due-date-reminder':
    case 'bootcamp-3days-after-due-date-reminder':
    case 'bootcamp-first-payment-reminder':
    case 'bootcamp-reminder-renewing-subscription-7days-before':
    case 'project-submission-deadline':
    case 'partner-project-submission-deadline':
      return <Reminder />;
    default:
      return null;
  }
};

IconNotification.propTypes = {
  notificationType: PropTypes.string,
};

const NotificationIcons = ({announcementType, color, notificationType}) => {
  return (
    <div className={`${notifIcon} pb1 br-100`} style={{backgroundColor: color}}>
      {announcementType && (
        <IconAnnouncement announcementType={announcementType} color={color} />
      )}
      {notificationType && (
        <IconNotification notificationType={notificationType} />
      )}
    </div>
  );
};

NotificationIcons.propTypes = {
  announcementType: PropTypes.string,
  color: PropTypes.string,
  notificationType: PropTypes.string,
};

export default NotificationIcons;
