import React from 'react';

class EarnPoint extends React.Component {
  static defaultProps = {
    color: '#62CA92',
    width: '57px',
    height: '57px',
  };

  render() {
    return (
      <svg
        version="1.1"
        width={this.props.width}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 57 57">
        <g fill="none" fillRule="evenodd">
          <circle cx="28.5" cy="28.5" r="28.5" fill={this.props.color} />
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M21.6 14.6c.6.4 1.2 1 1.7 1.6a15 15 0 0 0 1.4 1.5H32l1.3-1.3c.4-.6.8-1.2 1.7-1.7.7-.4 1.1-1.5.2-1.8-.8-.3-1.2.3-2.1.3-1 0-1-.7-2-1-1-.4-1.4 0-2 .4-.5.5-.7.5-.7.5l-1-.7c-.4-.4-1.3-.5-2-.1s-1 .8-1.6.9c-.7.1-1.9-.7-2.5-.2-.6.5-.3 1.3.3 1.6zm15 9c-2.7-2.1-4.2-4.1-4.2-4.1h-8s-1.5 2-4 4c-2.8 2.3-5 6-4.2 10.3.8 3.8 4.7 8.2 12.2 8.2 7.3 0 11.6-3.6 12.4-8 1-5.4-1.9-8.6-4.2-10.4zM33.5 31l-6.7 7.5c-.6.7-1 .5-1-.4l1-6h-2.9c-1 0-1.2-.6-.6-1.3l6.7-7.5c.7-.7 1-.6 1 .4l-1 6h3c1 0 1.2.6.6 1.3z"
          />
        </g>
      </svg>
    );
  }
}

export default EarnPoint;
