import React from 'react';

class LeaderBoard extends React.Component {
  static defaultProps = {
    width: '17px',
    height: '17px',
    color: '#62CA92',
  };

  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill={this.props.color}
          d="M15.44 3.266c-.578-1.025-1.68-1.573-2.866-1.563l.045-.415C12.696.586 12.278 0 11.683 0H4.145C3.55 0 3.133.586 3.21 1.288l.061.555C2.077 1.791.958 2.357.38 3.448c-.877 1.654-.18 3.879 1.555 4.97a3.711 3.711 0 0 0 2.14.586c.582 2.963 3.324 2.05 3.366 3.746 0 2.361-.472 3.305-3.3 4.25h7.542c-2.828-1.417-3.3-1.889-3.3-4.25.043-1.755 3.025-1.061 3.426-4.086a4.05 4.05 0 0 0 2.025-.573c1.794-1.06 2.513-3.22 1.607-4.825zM2.388 7.562c-1.302-.818-1.85-2.44-1.223-3.62.428-.808 1.293-1.2 2.214-1.106l.208 1.903.36 3.285a2.903 2.903 0 0 1-1.56-.462zm7.84-3.294l-.892.823.199 1.096a.292.292 0 0 1 .025.118.316.316 0 0 1-.333.307.337.337 0 0 1-.15-.035L7.973 6.03l-1.101.548a.34.34 0 0 1-.342-.023.3.3 0 0 1-.129-.3l.21-1.163-.891-.823a.297.297 0 0 1-.082-.316.32.32 0 0 1 .262-.21l1.231-.168.552-1.059c.109-.21.472-.21.58 0l.553 1.059 1.23.169a.32.32 0 0 1 .262.21.297.297 0 0 1-.081.315zm3.137 2.992c-.468.277-.968.42-1.448.444l.324-2.965.227-2.075c.903-.046 1.738.333 2.16 1.081.647 1.147.082 2.72-1.263 3.515z"
        />
      </svg>
    );
  }
}

export default LeaderBoard;
