import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import log from 'core/libs/logger';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    log(error, {
      info,
      currenturl: typeof window !== 'undefined' && window.location.href,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <p className="pa2">
          <FormattedMessage id="something_went_wrong" />
        </p>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
