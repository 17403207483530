import dateDiffInDays from './dateDiffInDays';
import isRequired from './isRequired';
import timeSince from './timeSince';

function formattedRelativeTime(
  date = isRequired('date', 'formattedRelativeTime'),
) {
  const createdAtDate = date && new Date(date);
  const currentDate = new Date();
  const seconds = (currentDate.getTime() - createdAtDate.getTime()) / 1000;
  const formattedRelative = timeSince(
    new Date(
      Date.now() -
        (dateDiffInDays(new Date(date), new Date()) > 0
          ? 24 * 60 * 60 * 1000 * dateDiffInDays(new Date(date), new Date())
          : seconds * 1000),
    ),
  );

  return formattedRelative;
}

export default formattedRelativeTime;
