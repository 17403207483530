/* eslint no-underscore-dangle:0 */
import 'core-js/modules/es6.map'; // react-dom
import 'core-js/modules/es6.set'; // react-dom
import ApolloProvider from 'core/ApolloProvider';
import LocationRecorder from 'core/libs/logger/LocationRecorder';
import store, {persistor} from 'core/store';
import mixpanel from 'mixpanel-browser';
import Layout from 'modules/common/Layout';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import PropTypes from 'prop-types';
import 'raf/polyfill';
import React from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import Stickyfill from 'stickyfilljs';

export const onClientEntry = () => {
  const isBrowser = typeof window !== 'undefined';
  if (process.env.GATSBY_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN);
    if (isBrowser) {
      window.mixpanel = mixpanel;
    }
  }
  window.Stickyfill = Stickyfill;
  const stickyElements = document.querySelectorAll('.sticky');
  Stickyfill.add(stickyElements);
  window.___emitter.on(`onDelayedLoadPageResources`, () => {
    document.getElementById('barmej-loader').classList.add('loader-visible');
  });
  window.___emitter.on(`onPostLoadPageResources`, () => {
    document.getElementById('barmej-loader').classList.remove('loader-visible');
  });
};

export const wrapRootElement = ({element}) => (
  <Provider store={store}>
    <PersistGate loading={<LoadingIndicator />} persistor={persistor}>
      <ApolloProvider>
        <>
          {element}
          <LocationRecorder />
        </>
      </ApolloProvider>
    </PersistGate>
  </Provider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export const wrapPageElement = ({element}) => {
  return <Layout>{element}</Layout>;
};

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
};
