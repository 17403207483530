import {combineReducers} from 'redux';

const stats = (state = {data: {}}, action) => {
  switch (action.type) {
    case 'GET_REFERRAL_STATS_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_REFERRAL_STATS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_REFERRAL_STATS_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const invitationsList = (state = {data: [], status: 'not fetched'}, action) => {
  switch (action.type) {
    case 'GET_INVITATIONS_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_INVITATIONS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_INVITATIONS_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const invitationsResult = (
  state = {data: [], status: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'CREATE_INVITATIONS_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'CREATE_INVITATIONS_SUCCESS':
      return {
        ...state,
        status: 'fetched',
      };
    case 'CREATE_INVITATIONS_FAILURE':
      return {
        ...state,
        data: action.error.response && action.error.response.data,
        status: 'rejected',
      };
    default:
      return state;
  }
};

export default combineReducers({
  stats,
  invitationsList,
  invitationsResult,
});
