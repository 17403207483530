import log from 'core/libs/logger';
import {persistor} from 'core/store';

const iOS =
  typeof window !== 'undefined' &&
  /iPad|iPhone|iPod/.test(window.navigator.userAgent) &&
  !window.MSStream;

const socialSignOut = (provider) =>
  new Promise((resolve, reject) => {
    window.logoutSocial = () => {
      switch (provider) {
        case 'google':
          window.gapi.load('auth2', () => {
            window.gapi.auth2
              .init({
                client_id: process.env.GOOGLE_PLUS_CLIENT_ID,
                scope:
                  'email profile https://www.googleapis.com/auth/plus.login',
              })
              .then((GoogleAuth) => {
                GoogleAuth.signOut()
                  .then(() => {
                    // console.log('logout from google: success');
                    delete window.logoutSocial;
                    resolve();
                  })
                  .catch(() => reject());
              });
          });
          break;
        case 'facebook': {
          const facebookLogout = (response) => {
            if (response.status === 'connected') {
              window.FB.logout(() => {
                // console.log('after logout', res);
                delete window.logoutSocial;
                resolve();
              });
            } else {
              reject();
            }
            window.FB.Event.unsubscribe('auth.statusChange', facebookLogout);
          };
          window.FB.Event.subscribe('auth.statusChange', facebookLogout);
          window.FB.init({
            appId: process.env.FACEBOOK_APP_ID,
            cookie: false,
            xfbml: false,
            version: 'v2.3',
            status: true,
          });
          break;
        }
        default:
          break;
      }
    };

    const tag = document.createElement('script');
    switch (provider) {
      case 'google':
        if (window.gapi) {
          window.logoutSocial();
        } else {
          tag.src =
            'https://apis.google.com/js/platform.js?onload=logoutSocial';
        }
        break;
      case 'facebook':
        if (window.FB) {
          window.FB.logout(() => {
            // console.log('after logout', res);
            delete window.logoutSocial;
            resolve();
          });
        } else {
          tag.id = 'facebook-jssdk';
          tag.src = `https://connect.facebook.net/en_US/sdk.js`;
          window.fbAsyncInit = window.logoutSocial;
        }
        break;
      default:
        break;
    }
    if (tag.src) {
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  });

export const signOut = () => (dispatch, getState) => {
  const {provider} = getState().auth;
  const logOutSocialPromise = socialSignOut(provider);

  dispatch({
    types: ['SIGN_OUT_START', 'SIGN_OUT_COMPLETE', 'SIGN_OUT_ERROR'],
    config: {
      url: 'sign-out/',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    callback: () => {
      if (persistor) {
        persistor.purge();
      }
      if (window && window.mixpanel) {
        window.mixpanel.reset();
      }
      window.location.href = `${window.location.origin}/auth/login`;
      logOutSocialPromise
        .then(() => {})
        .catch(() =>
          log('could not log out correctly from provider: ', provider),
        );
    },
  });
};

export default signOut;
