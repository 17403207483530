import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import FieldWrapperWithLabel from '../FieldWrapperWithLabel';
import {borderBlueOnFocus} from './styles.scss';

const FormInputField = ({field, form, ...props}) => {
  const {touched, errors} = form;
  const {label, placeholder, type} = props;
  const Tag = type === 'textarea' ? 'textarea' : 'input';
  return (
    <FieldWrapperWithLabel htmlFor={field.name} label={label}>
      <div className="mt2">
        <Tag
          placeholder={placeholder}
          id={field.name}
          {...field}
          {...props}
          className={cx(borderBlueOnFocus, props.className)}
        />
        <div>
          {touched[field.name] && errors[field.name] && (
            <span
              className="red db absolute f7 fw3 mv2"
              data-testid="field-error"
            >
              {errors[field.name]}
            </span>
          )}
        </div>
      </div>
    </FieldWrapperWithLabel>
  );
};

FormInputField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default FormInputField;
