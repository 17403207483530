export const getNotifications = ({force, page = 1} = {}) => {
  return {
    types: [
      'GET_NOTIFICATIONS_REQUEST',
      'GET_NOTIFICATIONS_SUCCESS',
      'GET_NOTIFICATIONS_FAILURE',
    ],
    config: {
      url: `notify/notification/?page=${page}`,
    },
    payload: {page},
    shouldCallAPI: ({notifications}) =>
      !(
        notifications[page] &&
        (notifications[page].status === 'fetching' ||
          (notifications[page].status === 'fetched' && !force))
      ),
  };
};

export const updateLastSeenDate = () => (dispatch, getState) => {
  const notifications = getState().notifications[1];
  if (notifications) {
    const {data: {results}, last_seen_notifications} = notifications;
    // We only update it there is newer notifications
    if (
      results &&
      results.length &&
      (!last_seen_notifications ||
        results[0].created_at > last_seen_notifications)
    ) {
      dispatch({
        types: [
          'UPDATE_USER_LAST_SEEN_DATE_REQUEST',
          'UPDATE_USER_LAST_SEEN_DATE_SUCCESS',
          'UPDATE_USER_LAST_SEEN_DATE_FAILURE',
        ],
        config: {
          url: `notify/user-last-seen-notification/`,
          method: 'PATCH',
        },
        payload: {
          now: new Date().toISOString(),
        },
      });
    } else {
      dispatch({type: 'LAST_SEEN_DATE_UP_TO_DATE'});
    }
  }
};
