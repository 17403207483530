const accessToken = (state = {data: []}, action) => {
  switch (action.type) {
    case 'GET_DISQUS_ACCESS_TOKEN_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_DISQUS_ACCESS_TOKEN_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_DISQUS_ACCESS_TOKEN_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

export default accessToken;
