import {graphql} from '@apollo/client/react/hoc';
import withLocation from 'core/libs/hoc/withLocation';
import {LoadingBar} from 'core/libs/loadingBar';
import 'css-direction/css/css-direction.min.css';
import ErrorBoundary from 'modules/common/ErrorBoundary';
import GET_MY_SUBSCRIPTIONS from 'modules/common/graphql/subscriptions.graphql';
import ToggleSupportWidget from 'modules/support/components/ToggleSupportWidget';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {compose, setPropTypes, withProps} from 'recompose';
import 'tachyons/css/tachyons.min.css';
import AppPopup from 'theme/components/AppPopup';
import './assets/styles/template.scss';
import DoYouWantToReferFriendsPopup from './components/DoYouWantToReferFriendsPopup';
// import PointsSystemDiscountPopup from './components/PointsSystemDiscountPopup';
import Footer from './partials/Footer';
import Header from './partials/Header';

const authPaths = ['auth/login', 'auth/register', 'auth/password'];
const onBoardingPaths = ['/start'];
const referralInvitePath = ['account/referrals/invite'];
const maintenancePath = ['/maintenance'];
const degreePath = ['/degrees'];

const eqPathName = (pathname) => (path) => {
  const trimmedPath = path.replace(/^\/*|\/*$/, '').trim();
  const regexp = new RegExp(`^/${trimmedPath}(/.*)?$`, 'gi');
  return regexp.test(pathname);
};

const {PARTNER} = process.env;
const {DIVIDED_BY} = process.env;

const MasterComponent = ({
  authenticated,
  user,
  children,
  toggleReferralsPopup,
  isAStep,
  isAuthPage,
  isReferralsPopupVisible,
  isOnBoardingPage,
  isMaintenance,
  hasSubscription,
  // isPointsSystemDiscountPopupVisible,
  // togglePointsSystemDiscountPopup,
}) => {
  const availableRedeemPoints = user && user.total_score;
  const {pathname} = window.location;

  useEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'page_view_barmej',
      });
    }
  }, [pathname]);

  return (
    <div className="cf w-100">
      <ErrorBoundary>
        <LoadingBar />
      </ErrorBoundary>
      <ErrorBoundary>
        {!isAStep && !isAuthPage && !isOnBoardingPage && !isMaintenance && (
          <Header
            authenticated={authenticated}
            availableRedeemPoints={availableRedeemPoints}
            dividedBy={DIVIDED_BY}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        <div className="min-vh-100">
          {authenticated &&
            !isOnBoardingPage &&
            user.total_score <= 20 &&
            PARTNER === 'BARMEJ' && <AppPopup />}
          {isReferralsPopupVisible && (
            <DoYouWantToReferFriendsPopup handleClose={toggleReferralsPopup} />
          )}
          {/* {PARTNER === 'BARMEJ' &&
            isPointsSystemDiscountPopupVisible &&
            availableRedeemPoints > 0 && (
              <PointsSystemDiscountPopup
                handleClose={togglePointsSystemDiscountPopup}
                availableRedeemPoints={availableRedeemPoints}
                dividedBy={DIVIDED_BY}
              />
            )} */}
          {children}
          {!isOnBoardingPage && hasSubscription && (
            <ToggleSupportWidget authenticated={authenticated} />
          )}
        </div>
      </ErrorBoundary>
      <ErrorBoundary>
        {!isAStep && !isAuthPage && !isOnBoardingPage && <Footer />}
      </ErrorBoundary>
    </div>
  );
};

MasterComponent.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  isAStep: PropTypes.bool.isRequired,
  isAuthPage: PropTypes.bool.isRequired,
  isOnBoardingPage: PropTypes.bool.isRequired,
  isMaintenance: PropTypes.bool,
  isReferralsPopupVisible: PropTypes.bool.isRequired,
  toggleReferralsPopup: PropTypes.func.isRequired,
  user: PropTypes.object,
  hasSubscription: PropTypes.bool,
  // isPointsSystemDiscountPopupVisible: PropTypes.bool,
  // togglePointsSystemDiscountPopup: PropTypes.func,
};

const enhance = compose(
  withLocation,
  setPropTypes({
    location: PropTypes.object.isRequired,
  }),
  withProps(({location}) => {
    const splittedPath = location.pathname.split('/');
    const isDegreePath = degreePath.some(eqPathName(location.pathname));
    return {
      isAStep:
        ((splittedPath.length > 4 && !isDegreePath) ||
          (splittedPath.length > 5 && isDegreePath)) &&
        !referralInvitePath.some(eqPathName(location.pathname)) &&
        !(splittedPath[1] === 'community'),
      isAuthPage: authPaths.some(eqPathName(location.pathname)),
      isOnBoardingPage: onBoardingPaths.some(eqPathName(location.pathname)),
      isMaintenance: maintenancePath.some(eqPathName(location.pathname)),
    };
  }),
  connect(
    ({auth, theme, user}) => ({
      authenticated: auth.authenticated,
      isReferralsPopupVisible: !!theme.isReferralsPopupVisible,
      user: user.profile.data,
      isPointsSystemDiscountPopupVisible: !!theme.isPointsSystemDiscountPopupVisible,
    }),
    (dispatch) => ({
      toggleReferralsPopup: () => dispatch({type: 'TOGGLE_REFERRAL_POPUP'}),
      /* togglePointsSystemDiscountPopup: () =>
        dispatch({type: 'TOGGLE_POINTS_SYSTEM_DISCOUNT_POPUP'}), */
    }),
  ),
  // graphql(GET_MY_SUBSCRIPTIONS, {
  //   skip: ({authenticated}) => !authenticated,
  //   props: ({data}) => {
  //     const {error, loading, me} = data;
  //     if (loading) return {loading};
  //     if (error) return {error, loading: false};
  //     const hasSubscription =
  //       me &&
  //       me.subscriptionSet &&
  //       me.subscriptionSet.edges &&
  //       me.subscriptionSet.edges.length > 0;
  //     return {
  //       hasSubscription,
  //     };
  //   },
  // }),
);

export default enhance(MasterComponent);
