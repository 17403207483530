import {combineReducers} from 'redux';

const nextStepByCourse = (
  state = {data: {}, status: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'GET_NEXT_STEP_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_NEXT_STEP_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_NEXT_STEP_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};
const nextStep = (state = {data: {}}, action) => {
  switch (action.type) {
    case 'GET_NEXT_STEP_REQUEST':
    case 'GET_NEXT_STEP_SUCCESS':
    case 'GET_NEXT_STEP_FAILURE':
      return {
        ...state,
        [action.slug]: nextStepByCourse(state[action.slug], action),
      };
    default:
      return state;
  }
};

const trackInterest = (state = {data: {}, status: 'not fetched'}, action) => {
  switch (action.type) {
    case 'POST_TRACK_INTEREST_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'POST_TRACK_INTEREST_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'POST_TRACK_INTEREST_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const trackInterestList = (state = {}, action) => {
  switch (action.type) {
    case 'POST_TRACK_INTEREST_REQUEST':
    case 'POST_TRACK_INTEREST_SUCCESS':
    case 'POST_TRACK_INTEREST_FAILURE':
      return {
        ...state,
        [action.id]: trackInterest(state[action.id], action),
      };
    default:
      return state;
  }
};

export default combineReducers({nextStep, trackInterestList});
