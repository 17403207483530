/* eslint no-console: 0 */
import {ApolloLink, ApolloProvider as Provider} from '@apollo/client';
import {InMemoryCache} from '@apollo/client/cache';
import {ApolloClient} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import {createUploadLink} from 'apollo-upload-client';
import notification from 'core/libs/helpers/notification';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {compose, withPropsOnChange} from 'recompose';
import fetch from 'unfetch';

const ApolloProvider = ({children, client}) => {
  return <Provider client={client}>{children}</Provider>;
};

ApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
  client: PropTypes.object,
};

const enhance = compose(
  connect(({auth}) => ({apiKey: auth.key, authenticated: auth.authenticated})),
  withPropsOnChange(['apiKey', 'authenticated'], ({apiKey, authenticated}) => {
    const IS_DEV_ENV =
      process.env.NODE_ENV !== 'production' || process.env.GATSBY_DEBUG == 1;

    const GRAPHQL_ENDPOINT = authenticated
      ? `${process.env.GATSBY_API2}graphql`
      : `${process.env.GATSBY_API2}v2/graphql`;

    const httpLink = createUploadLink({
      fetch,
      uri: GRAPHQL_ENDPOINT,
    });

    const errorLink = onError(({networkError, graphQLErrors}) => {
      if (graphQLErrors) {
        notification({
          id: 'server_error_try_again',
        });
        graphQLErrors.map(({message, locations, path}) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError)
        notification({
          message: `[Network error]: ${networkError}`,
        });
    });

    const authLink = setContext((_, {headers}) => ({
      headers: {
        ...headers,
        apiKey,
        'Accept-Language': 'ar',
      },
    }));

    const link = IS_DEV_ENV
      ? ApolloLink.from([apolloLogger, errorLink, authLink.concat(httpLink)])
      : ApolloLink.from([errorLink, authLink.concat(httpLink)]);

    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link,
    });
    return {client};
  }),
);

export default enhance(ApolloProvider);
