const initialState = {
  currentStep: 0,
  gameEnded: false,
  taskStatus: 'not started',
  tasksHistory: {},
  timestamp: undefined,
};
const quizGame = (state = initialState, action) => {
  switch (action.type) {
    case 'QUIZ_NEW_GAME':
      return initialState;
    case 'QUIZ_CHECK_ANSWER_REQUEST':
      return {
        ...state,
        taskStatus: 'checking',
        timestamp: undefined,
      };
    case 'QUIZ_CHECK_ANSWER_SUCCESS':
      return {
        ...state,
        taskStatus: 'fetching',
        timestamp: action.currentTime,
      };
    case 'QUIZ_CHECK_ANSWER_ERROR':
      return {
        ...state,
        taskStatus: 'rejected',
      };
    case 'SAVE_TASK_RESULT': {
      const {taskId, isCorrect} = action.payload;
      return {
        ...state,
        tasksHistory: {
          ...state.tasksHistory,
          [taskId]: isCorrect,
        },
      };
    }
    case 'RESET_TASKS':
      return {
        ...state,
        tasksHistory: initialState.tasksHistory,
      };

    default:
      return state;
  }
};

export default quizGame;
