// Here you must reference API URL for your entity
export const ENTITIES_URL = {
  certificates: 'school/certificates/',
  privacies: 'privacies/',
};

function entity(
  state = {
    status: 'not fetched',
  },
  action,
) {
  switch (action.type) {
    case 'GET_ENTITY_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_ENTITY_SUCCESS':
      return {
        ...state,
        ...action.response.data,
        status: 'fetched',
      };
    case 'FEED_ENTITY':
      return {
        ...state,
        ...action.data,
        status: 'fetched',
      };
    case 'GET_ENTITY_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
}

function category(state = {status: 'not fetched'}, action) {
  switch (action.type) {
    case 'GET_ENTITY_REQUEST':
    case 'GET_ENTITY_FAILURE':
      return {
        ...state,
        [action.selected]: entity(state[action.selected], action),
      };
    case 'GET_ENTITY_SUCCESS':
      return {
        ...state,
        [action.selected]: entity(state[action.selected], action),
      };
    case 'FEED_ENTITY':
      return {
        ...state,
        [action.data.slug || action.data.id]: entity(
          state[action.data.slug || action.data.id],
          action,
        ),
      };
    case 'GET_ENTITIES_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_ENTITIES_SUCCESS': {
      const items = {};
      action.response.data.forEach((item) => {
        // We add the data if it does not already exists
        if (
          !(
            state[item.slug || item.id] &&
            state[item.slug || item.id].status === 'fetched'
          )
        ) {
          items[item.slug || item.id] = {
            ...item,
            status: 'half fetched',
          };
        }
      });
      return {
        ...state,
        ...items,
        status: 'fetched',
      };
    }
    case 'FEED_ENTITIES':
      return {
        ...state,
        ...action.data.reduce((acc, item) => {
          acc[item.node.slug || item.node.id] = {
            ...item.node,
            status: 'fetched',
          };
          return acc;
        }, {}),
        status: 'fetched',
      };
    case 'GET_ENTITIES_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
}

function entities(state = {}, action) {
  switch (action.type) {
    case 'GET_ENTITY_SUCCESS':
    case 'GET_ENTITY_REQUEST':
    case 'GET_ENTITY_FAILURE':
    case 'GET_ENTITIES_SUCCESS':
    case 'GET_ENTITIES_REQUEST':
    case 'GET_ENTITIES_FAILURE':
    case 'FEED_ENTITY':
    case 'FEED_ENTITIES':
      return {
        ...state,
        [action.category]: category(state[action.category], action),
      };
    default:
      return state;
  }
}

export default entities;
