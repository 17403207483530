import NewDesignButton from 'modules/common/NewDesignButton';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const NavLandingComponent = () => {
  const intl = useIntl();
  const {formatMessage} = intl;
  return (
    <div className="ph4-l flex justify-start-ns justify-center items-center">
      <NewDesignButton
        link="/auth/login"
        className="link dim pa3 ba b--white"
        title={formatMessage({id: 'sign_in'})}
      >
        <FormattedMessage id="sign_in" />
      </NewDesignButton>
      <NewDesignButton
        link="/auth/register"
        className="link dim mr3 ba b--green-light pa3 bg-green-light"
        title={formatMessage({id: 'sign_up'})}
      >
        <FormattedMessage id="sign_up" />
      </NewDesignButton>
    </div>
  );
};

export default NavLandingComponent;
