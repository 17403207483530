const organizeByType = (data) => {
  const organizedData = {};
  data.forEach(({content_type: {model}, object_id: id, status}) => {
    if (!organizedData[model]) {
      organizedData[model] = {};
    }
    organizedData[model][id] = status;
  });
  return organizedData;
};

const progress = (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) => {
  switch (action.type) {
    case 'GET_PROGRESS_REQUEST':
      return {
        ...state,
        fetching: true,
      };
    case 'GET_PROGRESS_SUCCESS':
      return {
        ...state,
        ...organizeByType(action.response.data),
        fetching: true,
        fetched: true,
      };
    case 'GET_PROGRESS_FAILURE':
      return {
        ...state,
        fetching: false,
        rejected: true,
      };
    case 'UPDATE_PROGRESS':
      return {
        ...state,
        step: {
          ...state.step,
          [action.id]: action.completed ? 'completed' : 'started',
        },
      };
    default:
      return state;
  }
};

export default progress;
