const theme = (
  state = {
    isReferralsPopupVisible: false,
    sidebar: true,
    codechallengeSidebarVisible: true,
    isPointsSystemDiscountPopupVisible: false,
  },
  action,
) => {
  switch (action.type) {
    case 'TOGGLE_REFERRAL_POPUP':
      return {
        isReferralsPopupVisible: !state.isReferralsPopupVisible,
      };
    case 'TOGGLE_SIDEBAR':
      if (state.sidebar && state.codechallengeSidebarVisible)
        return {
          ...state,
          sidebar: !state.sidebar,
        };
      return {
        ...state,
        sidebar: true,
        codechallengeSidebarVisible: true,
      };
    case 'CODE_CHALLENGE_SIDEBAR_SHOW':
      return {
        ...state,
        codechallengeSidebarVisible: true,
      };
    case 'CODE_CHALLENGE_SIDEBAR_HIDE':
      return {
        ...state,
        codechallengeSidebarVisible: false,
      };
    case 'CLOSE_SIDEBAR':
      return {
        sidebar: false,
      };
    case 'TOGGLE_POINTS_SYSTEM_DISCOUNT_POPUP':
      return {
        isPointsSystemDiscountPopupVisible: !state.isPointsSystemDiscountPopupVisible,
      };
    default:
      return state;
  }
};

export default theme;
