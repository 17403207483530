import {combineReducers} from 'redux';

const profileUser = (state = {data: [], status: 'not fetched'}, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_USER_PROFILE_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_USER_PROFILE_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const achievementsUser = (
  state = {data: {}, status: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'GET_ACHIEVEMENTS_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_ACHIEVEMENTS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_ACHIEVEMENTS_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const certificatesUser = (
  state = {data: [], status: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'GET_CERTIFICATES_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_CERTIFICATES_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_CERTIFICATES_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const latestPosts = (state = {data: [], status: 'not fetched'}, action) => {
  switch (action.type) {
    case 'GET_LATEST_POSTS_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_LATEST_POSTS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_LATEST_POSTS_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

export default combineReducers({
  profileUser,
  achievementsUser,
  certificatesUser,
  latestPosts,
});
