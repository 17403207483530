import notification from 'core/libs/helpers/notification';

const ZENDESK_API = 'https://barmej.zendesk.com/api/v2/';

export const createZendeskTicket = (values, callback) => {
  const data = {
    request: {
      requester: {
        name: `${values.first_name} ${values.last_name || ''}`,
        email: values.email,
      },
      subject: values.subject || values.body,
      comment: {
        body: `${values.body}
           ----------
           Sent from: ${window.location.href}`,
      },
    },
  };
  return {
    types: [
      'CREATE_ZENDESK_TICKET_REQUEST',
      'CREATE_ZENDESK_TICKET_SUCCESS',
      'CREATE_ZENDESK_TICKET_FAILURE',
    ],
    config: {
      url: `${ZENDESK_API}requests`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    },
    callback: (success, result) => {
      if (result) {
        notification({
          id: 'success_message_sent',
          type: 'success',
        });
        callback();
      } else if (result.response.data.status >= 400) {
        const notificationMessage = Object.values(
          result.response.data,
        ).toString();
        notification({message: notificationMessage});
      } else {
        notification({message: 'Service Temporarily Unavailable'});
      }
    },
  };
};

export default createZendeskTicket;
