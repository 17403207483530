import {combineReducers} from 'redux';

const scores = (state = {data: [], status: 'not fetched'}, action) => {
  switch (action.type) {
    case 'GET_LEADER_BOARD_DATA_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_LEADER_BOARD_DATA_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_LEADER_BOARD_DATA_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const scoresOfSelectedTrack = (
  state = {data: [], status: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'GET_LEADER_BOARD_DATA_OF_SELECTED_TRACK_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_LEADER_BOARD_DATA_OF_SELECTED_TRACK_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_LEADER_BOARD_DATA_OF_SELECTED_TRACK_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const userRank = (
  state = {data: {}, status: 'not fetched'},
  {type, response, track_slug, user_id},
) => {
  switch (type) {
    case 'GET_USER_POSITION_REQUEST':
      return {...state, track_slug, user_id, status: 'fetching'};
    case 'GET_USER_POSITION_SUCCESS':
      return {...state, data: response.data, status: 'fetched'};
    case 'GET_USER_POSITION_FAILURE':
      return {...state, status: 'rejected'};
    default:
      return state;
  }
};

const userScore = (
  state = {data: {}, status: 'not fetched'},
  {type, response, track_slug, user_id},
) => {
  switch (type) {
    case 'GET_USER_SCORE_REQUEST':
      return {...state, track_slug, user_id, status: 'fetching'};
    case 'GET_USER_SCORE_SUCCESS':
      return {...state, data: response.data, status: 'fetched'};
    case 'GET_USER_SCORE_FAILURE':
      return {...state, status: 'rejected'};
    default:
      return state;
  }
};

const userScoreOfSelectedTrack = (
  state = {data: {}, status: 'not fetched'},
  {type, response, track_slug, user_id},
) => {
  switch (type) {
    case 'GET_USER_SCORE_OF_SELECTED_TRACK_REQUEST':
      return {...state, track_slug, user_id, status: 'fetching'};
    case 'GET_USER_SCORE_OF_SELECTED_TRACK_SUCCESS':
      return {...state, data: response.data, status: 'fetched'};
    case 'GET_USER_SCORE_OF_SELECTED_TRACK_FAILURE':
      return {...state, status: 'rejected'};
    default:
      return state;
  }
};

export default combineReducers({
  scores,
  scoresOfSelectedTrack,
  userRank,
  userScore,
  userScoreOfSelectedTrack,
});
