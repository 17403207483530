/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from 'classnames';
import {Field, withFormik} from 'formik';
import FormInputField from 'modules/common/FormInputField';
import Portals from 'modules/common/Portals';
import Close from 'modules/common/Svg/Close';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import * as Yup from 'yup';
import {createZendeskTicket} from '../createZendeskTicket';

const SupportWidget = ({
  handleSubmit,
  touched,
  errors,
  intl: {formatMessage},
  toggleModalWidget,
  authenticated,
}) => (
  <Portals>
    <div className="bg-white z-9999 ba br3 b--light-gray shadow-4 b--light-gray fixed mh3 mb3 w-25-ns h-auto left-0 bottom-0">
      <div className="flex justify-between pv2 bb b--light-gray">
        <div className="flex2" />
        <div className="mt3">
          <FormattedMessage id="call_us" />
        </div>
        <div className="flex2 tl mt2 ml3">
          <a onClick={toggleModalWidget}>
            <Close bgColor="#bbb" className="pointer dim" />
          </a>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={cx('cf pv2 mr3', {dn: authenticated})}>
          <label className="fr w-100 fw6 pv1" htmlFor="first_name">
            {formatMessage({id: 'label_first_name'})}
          </label>
          <div className="fr w-100">
            <Field
              name="first_name"
              type="text"
              component={FormInputField}
              placeholder={formatMessage({id: 'label_first_name'})}
              className="w-100 w-90-ns"
            />
          </div>
        </div>
        <div className={cx('cf pv2 mr3', {dn: authenticated})}>
          <label className="fr w-100 fw6 pv1" htmlFor="email">
            {formatMessage({id: 'label_email_adress'})}
          </label>
          <div className="fr w-100">
            <Field
              name="email"
              type="email"
              component={FormInputField}
              placeholder={formatMessage({id: 'label_email_adress'})}
              className="w-100 w-90-ns"
            />
          </div>
        </div>
        <div className="cf pv2 mr3">
          <label className="fr w-100 fw6 pv1 mt2" htmlFor="body">
            {formatMessage({id: 'how_we_can_help_you'})}
          </label>
          <div className="fr w-100">
            <Field
              name="body"
              className="pa2 input-reset ba b--light-gray bg-transparent h4 fw4 w-100 w-90-ns"
              component="textarea"
              placeholder={formatMessage({id: 'how_we_can_help_you'})}
            />
            {touched.body && errors.body && (
              <span className="red db absolute f7 fw3 mv2">{errors.body}</span>
            )}
          </div>
        </div>
        <div className="bt b--light-gray pa3 mt3">
          <button
            aria-label="Send your message"
            type="submit"
            className="b--none pointer br2 bg-blue bg-animate hover-bg-dark-blue white ph4 pv2 ml2"
          >
            <FormattedMessage id="send_your_message" />
          </button>
        </div>
      </form>
    </div>
  </Portals>
);

const mapStateToProps = (state) => {
  const initialValues = {};
  Object.entries(state.user.profile.data).forEach(([key, value]) => {
    initialValues[key] = value !== 'null' ? value : '';
  });
  return {
    initialValues,
  };
};

SupportWidget.propTypes = {
  intl: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  toggleModalWidget: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

const enhance = compose(
  connect(mapStateToProps),
  injectIntl,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({initialValues}) => {
      return {
        first_name: initialValues.first_name || '',
        email: initialValues.email || '',
        body: '',
      };
    },
    validationSchema: ({intl: {formatMessage}}) =>
      Yup.object().shape({
        email: Yup.string()
          .email(formatMessage({id: 'error_email_invalid'}))
          .required(formatMessage({id: 'enter_email'})),
        body: Yup.string().required(
          formatMessage({id: 'enter_how_we_can_help_you'}),
        ),
      }),
    handleSubmit: (values, {props}) => {
      props.dispatch(
        createZendeskTicket(values, () => {
          props.toggleModalWidget();
        }),
      );
    },
  }),
);

export default enhance(SupportWidget);
