import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

const LegacyLink = ({children, ...props}) =>
  props.to !== undefined ? (
    <Link {...props}>{children}</Link>
  ) : (
    <a {...props}>{children}</a>
  );

LegacyLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default LegacyLink;
