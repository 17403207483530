import {useEffect} from 'react';

const useOneTimeMouseExit = (localStorageItemName, callback) => {
  useEffect(() => {
    if (
      window &&
      window.localStorage &&
      !localStorage.getItem(localStorageItemName)
    ) {
      const handleMouseOut = (e) => {
        if (e.clientY < 0) {
          document.removeEventListener('mouseout', handleMouseOut);
          localStorage.setItem(localStorageItemName, true);
          callback();
        }
      };
      document.addEventListener('mouseout', handleMouseOut);

      return () => {
        document.removeEventListener('mouseout', handleMouseOut);
      };
    }
    return undefined;
  }, [localStorageItemName, callback]);
};

export default useOneTimeMouseExit;
