const siteData = require('../../../data/siteData.json');

function findSiteData(property) {
  const {PARTNER} = process.env;
  const findElement = siteData.find((element) => element.name === PARTNER);

  return findElement[property];
}

module.exports.findSiteData = findSiteData;
