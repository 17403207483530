import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {container} from './styles.scss';

const LoadingIndicator = ({className, fullScreen = true}) => (
  <div className={cx('tc', className, {[container]: fullScreen})}>
    <div className="flex justify-center items-center spinner">
      <div className="dot-1" />
      <div className="dot-2" />
      <div />
    </div>
  </div>
);

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
};

export default LoadingIndicator;
