import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

const NotificationLink = ({children, url}) =>
  !url || /^https?:\/\//.test(url) ? (
    <a href={url}>{children}</a>
  ) : (
    <Link to={url}>{children}</Link>
  );

NotificationLink.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
};

export default NotificationLink;
