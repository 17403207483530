import React from 'react';
import PropTypes from 'prop-types';

class Notification extends React.Component {
  render() {
    const {color, width, className} = this.props;
    return (
      <svg
        width={width}
        height="16"
        xmlns="http://www.w3.org/2000/svg"
        className={className}>
        <path
          fill={color}
          d="M5.688 1.876c-2.265.57-3.938 2.584-3.938 4.98v3.43a.578.578 0 0 1-.583.571C.523 10.857 0 11.37 0 12c0 .631.523 1.143 1.17 1.143h11.66c.646 0 1.17-.513 1.17-1.143 0-.631-.525-1.143-1.167-1.143a.576.576 0 0 1-.583-.572V6.857c0-2.396-1.673-4.41-3.938-4.98v-.591C8.313.574 7.726 0 7 0c-.723 0-1.313.576-1.313 1.286v.59zm-.73 11.838h4.084c0 1.105-.914 2-2.042 2-1.128 0-2.042-.895-2.042-2z"
        />
      </svg>
    );
  }
}

Notification.defaultProps = {
  className: '',
  width: '16px',
  color: 'white',
};

Notification.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

export default Notification;
