import React from 'react';
import PropTypes from 'prop-types';
import withNotifications from 'modules/notifications/withNotifications';
import {Link} from 'gatsby';
import {FormattedMessage} from 'react-intl';
import {paddingNotifiy} from './styles.scss';

const NotificationMobileButton = ({notificationCount, updateLastSeenDate}) => (
  <div onClick={updateLastSeenDate}>
    <Link
      className="flex pa3 dim pointer bb b--light-gray white"
      to="/notifications">
      <div className="flex center">
        {notificationCount > 0 && (
          <p className={`bg-moon-gray ${paddingNotifiy} br2`}>
            {notificationCount}
          </p>
        )}
        <p className="pr2">
          <FormattedMessage id="notifications_menu" />
        </p>
      </div>
    </Link>
  </div>
);

NotificationMobileButton.propTypes = {
  notificationCount: PropTypes.number.isRequired,
  updateLastSeenDate: PropTypes.func.isRequired,
};

export default withNotifications(NotificationMobileButton);
