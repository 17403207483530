import useOneTimeMouseExit from 'core/libs/hooks/useOneTimeMouseExit';
import {graphql, useStaticQuery} from 'gatsby';
import Link from 'modules/common/Link';
import Popup from 'modules/common/Popup';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import popupIllustration from 'theme/assets/images/popup.svg';
import {actionButton, details} from './styles.scss';

const AppPopup = () => {
  const trackData = useStaticQuery(graphql`
    {
      fugoki {
        allTracks(id: "VHJhY2tUeXBlOjEwMDM=") {
          edges {
            node {
              id
              slug
              trackstepSet {
                edges {
                  node {
                    id
                    course {
                      id
                      slug
                      stageSet {
                        edges {
                          node {
                            id
                            slug
                            stepSet {
                              edges {
                                node {
                                  id
                                  slug
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (
    !trackData.fugoki.allTracks.edges[0] ||
    !trackData.fugoki.allTracks.edges[0].node
  )
    return null;
  const trackSlug = trackData.fugoki.allTracks.edges[0].node.slug;
  if (
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet ||
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0] ||
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node ||
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
  )
    return null;
  const courseSlug =
    trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .slug;
  if (
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .stageSet ||
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .stageSet.edges[0] ||
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .stageSet.edges[0].node
  )
    return null;
  const stageSlug =
    trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .stageSet.edges[0].node.slug;
  if (
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .stageSet.edges[0].node.stepSet ||
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .stageSet.edges[0].node.stepSet.edges[0] ||
    !trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .stageSet.edges[0].node.stepSet.edges[0].node
  )
    return null;
  const stepSlug =
    trackData.fugoki.allTracks.edges[0].node.trackstepSet.edges[0].node.course
      .stageSet.edges[0].node.stepSet.edges[0].node.slug;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isModalVisible, setModalVisible] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useOneTimeMouseExit('hide_on_exit_popup', () => {
    setModalVisible(true);
  });
  if (!isModalVisible) return null;

  const link = `/${trackSlug}/${courseSlug}/${stageSlug}/${stepSlug}`;

  const handleExitPopup = (event) => {
    setModalVisible(false);
    if (window && window.mixpanel) {
      window.mixpanel.track('App Popup', {
        type: 'click',
        location: 'barmej_app',
        event,
      });
    }
    if (typeof window !== 'undefined') {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'Exit Web App Popup',
        });
      }
    }
  };
  const exitPopup = () => handleExitPopup('exit');
  return (
    <Popup exitPopup={exitPopup}>
      <div className="w-50-ns w-80-m w-100 center">
        <img
          className="w-100"
          src={popupIllustration}
          alt="wait for a moment"
        />
      </div>
      <div className={details}>
        <h2>
          <FormattedMessage id="popup_wait_a_moment" />
        </h2>
        <p>
          <FormattedMessage id="popup_app_description" />
        </p>
      </div>
      <Link
        to={link}
        className={`${actionButton} bg-blue bg-animate hover-bg-dark-blue w-100 white bn pointer dib`}
        onClick={() => {
          handleExitPopup('promo');
        }}
      >
        <FormattedMessage id="popup_app_start_journey" />
      </Link>
    </Popup>
  );
};

export default AppPopup;
