import React from 'react';

class Announcement extends React.Component {
  static defaultProps = {
    width: '36px',
    height: '30px',
    color: '#31aa69',
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="2 15 36 5">
        <path
          fill={this.props.color}
          d="M24.2 11.9c-.9 0-1.6.7-1.6 1.6v.5l-12.3 3.9c-.1-.7-.7-1.2-1.4-1.2-.8 0-1.4.6-1.4 1.4v3.6c.1.7.7 1.3 1.5 1.3.7 0 1.3-.5 1.4-1.2l.8.3c-.1.1-.2.3-.3.5-.2.7.2 1.5.9 1.7l4.5 1.5c.1 0 .3.1.4.1.6 0 1.1-.4 1.3-.9.1-.2.1-.4 0-.6l4.6 1.5v.5c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6V13.5c0-.9-.7-1.6-1.6-1.6zm-7 12.7c-.1.3-.4.5-.7.4L12 23.5c-.3-.1-.5-.4-.4-.7.1-.3.4-.4.7-.4l4.5 1.5c.4.1.5.4.4.7zm-7.6-3c0 .3-.3.6-.6.6s-.6-.3-.6-.6V18c0-.3.3-.6.6-.6s.6.3.6.6v3.6zm.8-.6v-2.3l12.3-3.9v10L10.4 21zM25 26.2c0 .4-.4.8-.8.8s-.8-.4-.8-.8V13.5c0-.4.4-.8.8-.8s.8.4.8.8v12.7zM29.3 19.4h-.4c-.2 0-.4.2-.4.4s.2.4.4.4h.4c.2 0 .4-.2.4-.4s-.1-.4-.4-.4zM27.4 20.2h.4c.2 0 .4-.2.4-.4s-.2-.4-.4-.4h-.4c-.2 0-.4.2-.4.4 0 .3.1.4.4.4zM30.9 19.4h-.4c-.2 0-.4.2-.4.4s.2.4.4.4h.4c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM28.5 14.5c.1 0 .2 0 .3-.1l.3-.3c.2-.2.2-.4 0-.6s-.4-.2-.6 0l-.3.3c-.2.2-.2.4 0 .6.1.1.2.1.3.1zM29.6 13.4c.1 0 .2 0 .3-.1l.3-.3c.2-.2.2-.4 0-.6-.2-.2-.4-.2-.6 0l-.3.3c-.2.2-.2.4 0 .6.1.1.2.1.3.1zM27.4 15.6c.1 0 .2 0 .3-.1l.3-.3c.2-.2.2-.4 0-.6-.2-.2-.4-.2-.6 0l-.3.4c-.2.2-.2.4 0 .6h.3zM27.6 17.1l-.4.1c-.2.1-.3.3-.2.5.1.2.2.3.4.3h.1l.4-.1c.2-.1.3-.3.2-.5s-.3-.4-.5-.3zM28.9 17.5h.1l.4-.1c.2-.1.3-.3.2-.5-.1-.2-.3-.3-.5-.2l-.4.1c-.2.1-.3.3-.2.5 0 .1.2.2.4.2zM30 16.7c.1.2.2.3.4.3h.1l.4-.1c.2-.1.3-.3.3-.5-.1-.2-.3-.3-.5-.3l-.4.1c-.3.1-.4.3-.3.5zM29.9 26.4c-.2-.2-.4-.2-.6 0-.2.2-.2.4 0 .6l.3.3c.1.1.2.1.3.1.1 0 .2 0 .3-.1.2-.2.2-.4 0-.6l-.3-.3zM28.8 25.3c-.2-.2-.4-.2-.6 0-.2.2-.2.4 0 .6l.3.3c.1.1.2.1.3.1.1 0 .2 0 .3-.1.2-.2.2-.4 0-.6l-.3-.3zM27.6 24.2c-.2-.2-.4-.2-.6 0-.2.2-.2.4 0 .6l.3.3c.1.1.2.1.3.1s.2 0 .3-.1c.2-.2.2-.4 0-.6l-.3-.3zM28.7 22.9l.4.1h.1c.2 0 .3-.1.4-.3.1-.2 0-.4-.2-.5H29c-.2-.1-.4 0-.5.2s0 .5.2.5zM27.9 21.8l-.4-.1c-.2-.1-.4 0-.5.2-.1.2 0 .4.2.5l.4.1h.1c.2 0 .3-.1.4-.3.1-.1 0-.3-.2-.4zM30.9 22.8l-.4-.1c-.2-.1-.4 0-.5.3-.1.2 0 .4.3.5l.4.1h.1c.2 0 .3-.1.4-.3 0-.2-.1-.4-.3-.5z"
        />
      </svg>
    );
  }
}

export default Announcement;
