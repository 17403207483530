/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Structure from 'core/settings/structure';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import PointsSystemDiscount from 'theme/components/PointsSystemDiscount';
import NotificationMobileButton from './NotificationMobileButton';
import {menuStyleMobile} from './styles.scss';
import SubMenu from './SubMenu';

const {PARTNER} = process.env;

const MobileMenu = ({
  handleClick,
  handleSignOut,
  user,
  authenticated,
  availableRedeemPoints,
  dividedBy,
  loginProvider,
}) => (
  <div
    className={`z-999 db dn-ns absolute left-0 ${menuStyleMobile} min-h-100`}
  >
    <div className="pa3 dn-ns flex flex-column justify-around justify-between-ns items-center bb b--light-gray">
      <div className="flex white">
        <div className="ml1">{user.first_name}</div>
        <div>{user.last_name}</div>
      </div>
      {/* {PARTNER === 'BARMEJ' && availableRedeemPoints && (
        <div className="mt2">
          <PointsSystemDiscount
            availableRedeemPoints={availableRedeemPoints}
            dividedBy={dividedBy}
          />
        </div>
      )} */}
    </div>
    <div className="flex flex-column" onClick={handleClick}>
      <SubMenu
        className="dn-l"
        items={Structure.header.navMenu
          .filter(({hideAnonymous}) => authenticated || !hideAnonymous)
          .filter(({displayAnonymous}) => !displayAnonymous)
          .filter(
            ({hideForPartner}) => !hideForPartner || PARTNER === 'BARMEJ',
          )}
        isMobile
      />
      <NotificationMobileButton />
      <Link
        className="white pa3 dim black-90 tc tr-ns bb b--light-gray"
        to={`/user/${user.slug}`}
      >
        <FormattedMessage id="view_profile" />
      </Link>
      <SubMenu items={Structure.header.userMenu} isMobile loginProvider={loginProvider}/>
      <a
        className="white pa3 dim black-90 tc tr-ns pointer"
        onClick={handleSignOut}
      >
        <FormattedMessage id="log_out" />
      </a>
    </div>
  </div>
);

MobileMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  availableRedeemPoints: PropTypes.number.isRequired,
  dividedBy: PropTypes.string.isRequired,
  loginProvider: PropTypes.string,
};

export default MobileMenu;
