import {combineReducers} from 'redux';

const notificationsInfos = (
  state = {data: [], status: 'not fetched', updateStatus: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'GET_NOTIFY_PREFERENCE_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'UPDATE_NOTIFY_PREFERENCE_REQUEST':
      return {
        ...state,
        updateStatus: 'fetching',
      };
    case 'GET_NOTIFY_PREFERENCE_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'UPDATE_NOTIFY_PREFERENCE_SUCCESS': {
      const index = state.data.findIndex(
        ({id}) => id === action.response.data.id,
      );
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          action.response.data,
          ...state.data.slice(index + 1),
        ],
        updateStatus: 'fetched',
      };
    }
    case 'GET_NOTIFY_PREFERENCE_FAILURE':
      return {
        ...state,
        status: 'rejected',
      };
    case 'UPDATE_NOTIFY_PREFERENCE_FAILURE':
      return {
        ...state,
        updateStatus: 'rejected',
      };
    default:
      return state;
  }
};

export default combineReducers({
  notificationsInfos,
});
