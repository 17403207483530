const logger = (state = {locations: []}, action) => {
  switch (action.type) {
    case 'INIT_LOCATION':
    case 'CHANGE_LOCATION':
      return {...state, locations: [...state.locations, action.location]};
    default:
      return state;
  }
};

export default logger;
