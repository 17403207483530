// eslint-disable-next-line import/no-cycle
import store from 'core/store';

const logger = (ex, context) => {
  if (typeof window !== 'undefined') {
    if (window.Sentry) {
      const state = store.getState();
      const {id, email} =
        state && state.user && state.user.profile && state.user.profile.data;

      Sentry.configureScope((scope) => {
        if (id) {
          scope.setUser({id, email});
        }
        scope.setExtra(
          'url',
          typeof window !== 'undefined' && window.location.href,
        );
        if (context) {
          scope.setExtra('payload', context);
        } else {
          scope.setExtra('payload', null);
        }
      });

      Sentry.captureMessage(
        ex && ex.toString ? ex.toString() : JSON.stringify(ex),
        {
          fingerprint: [ex && ex.toString ? ex.toString() : JSON.stringify(ex)],
        },
      );
    }
    console.error(ex, context); // eslint-disable-line
  }
};

export default logger;
