import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';
import {getNotifications} from 'modules/notifications/actions';
// eslint-disable-next-line import/no-cycle
import {fetchUser} from './profile';

export const getProgress = ({callback, shouldCallAPI} = {}) => {
  return {
    types: [
      'GET_PROGRESS_REQUEST',
      'GET_PROGRESS_SUCCESS',
      'GET_PROGRESS_FAILURE',
    ],
    config: {
      url: 'school/user-progress/',
    },
    callback,
    shouldCallAPI: shouldCallAPI || ((state) => state.auth.authenticated),
  };
};

export default getProgress;

export const updateProgress = ({id, completed, related_steps}) => (
  dispatch,
  getState,
) => {
  const state = getState();
  // It is a new user that complete his first step
  if (!state.user.progress.step) {
    dispatch(getProgress());
    return;
  }
  // If the step is started or completed AND
  // the user did not complete, we don't update
  // Or the user already completed in the past, we don't update
  if (
    state.user.progress.step[id] &&
    (!completed || state.user.progress.step[id] === 'completed')
  ) {
    return;
  }
  // If all steps are not started we call api because it could update a course
  if (
    related_steps.every(
      ({id: relatedId}) => state.user.progress.step[relatedId] === undefined,
    )
  ) {
    dispatch(getProgress());
    return;
  }
  // If all other steps are completed we call api because it could complete a course and/or a track
  if (
    completed &&
    related_steps.every(
      ({id: relatedId}) =>
        id === relatedId || state.user.progress.step[relatedId] === 'completed',
    )
  ) {
    dispatch(
      getProgress({
        callback: (success, response) => {
          if (success) {
            const oldTracksProgress = state.user.progress.track;
            if (oldTracksProgress) {
              const newProgressArray = response.data;
              newProgressArray
                .filter(
                  ({content_type, status}) =>
                    content_type.model === 'track' && status === 'completed',
                )
                .forEach(({object_id}) => {
                  if (
                    oldTracksProgress[object_id] &&
                    oldTracksProgress[object_id] === 'started'
                  ) {
                    navigate('/account/certificates');
                    notification({
                      id: 'congratulations_finished_a_track',
                      type: 'success',
                    });
                    if (window) {
                      setTimeout(() => {
                        dispatch(getNotifications({force: true}));
                      }, 3000);
                    }
                  }
                });
            }
          }
        },
      }),
    );
    return;
  }
  // Else we can update without calling the api
  dispatch({
    type: 'UPDATE_PROGRESS',
    id,
    completed,
  });
  // Dispatch user score action when the step is completed
  dispatch(fetchUser({force: true}));
};
