import React, {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';

const Portals = ({children}) => {
  const elRef = useRef(null);
  if (!elRef.current) {
    const div = document.createElement('div');
    elRef.current = div;
  }

  useEffect(() => {
    const portalRoot = document.getElementById('portal');
    portalRoot.appendChild(elRef.current);

    return () => portalRoot.removeChild(elRef.current);
  }, []);

  return createPortal(<div className="rtl">{children}</div>, elRef.current);
};

export default Portals;
