/* eslint-disable jsx-a11y/alt-text */
import cx from 'classnames';
import {Link} from 'gatsby';
import ErrorBoundary from 'modules/common/ErrorBoundary';
import PropTypes from 'prop-types';
import React from 'react';
import BarmejLogo from 'theme/assets/images/barmej-white-logo.svg';
import KfasLogo from 'theme/assets/images/kfas-white-logo.svg';
//import PointsSystemDiscount from 'theme/components/PointsSystemDiscount';
import {NavLanding, NavMenu, NavProfile, NotificationUser} from '../Menus';
import {
  centerMobile,
  customBlue,
  header,
  kfasLogoWidth,
  logoWidth,
  selfCenter,
} from './styles.scss';

const {PARTNER} = process.env;

const HeaderComponent = ({
  className = '',
  authenticated,
  availableRedeemPoints,
  dividedBy,
}) => {
  const isMobile = window.innerWidth <= 630;
  return (
    <div
      className={cx('flex items-center', header, customBlue, className, {
        'flex-column flex-row-ns justify-center justify-between-ns': !authenticated,
        'justify-between': authenticated,
      })}
    >
      <div
        className={cx('flex h-100-ns', selfCenter, centerMobile, {
          'mb0-ns mb2': !authenticated,
        })}
      >
        <Link
          to={authenticated ? '/dashboard' : '/'}
          className="link link white db flex items-center pl3-ns"
        >
          {PARTNER === 'KFAS' && (
            <img src={KfasLogo} className={kfasLogoWidth} alt="kfas logo" />
          )}
          {PARTNER === 'BARMEJ' && (
            <img src={BarmejLogo} className={logoWidth} alt="barmej logo" />
          )}
        </Link>
        <NavMenu authenticated={authenticated} />
      </div>
      {authenticated ? (
        <div className="h-100 flex items-stretch justify-around white">
          {/* {PARTNER === 'BARMEJ' && availableRedeemPoints > 0 && !isMobile && (
            <PointsSystemDiscount
              availableRedeemPoints={availableRedeemPoints}
              dividedBy={dividedBy}
            />
          )} */}
          <ErrorBoundary>
            <NotificationUser />
          </ErrorBoundary>
          <NavProfile
            availableRedeemPoints={availableRedeemPoints}
            dividedBy={dividedBy}
          />
        </div>
      ) : (
        <NavLanding />
      )}
    </div>
  );
};

HeaderComponent.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  className: PropTypes.string,
  availableRedeemPoints: PropTypes.number,
  dividedBy: PropTypes.string.isRequired,
};

export default HeaderComponent;
