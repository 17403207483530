import React, {Component} from 'react';
import {Link} from 'gatsby';
import Structure from 'core/settings/structure';
import './style.scss';

const {PARTNER} = process.env;

export default class NavFooter extends Component {
  render() {
    return (
      <div className="fr w-100 w-60-m w-70-ns tr">
        <ul
          className="list tc tr-ns mb2 mb0-ns flex flex-row-ns flex-column pa0 ma0"
          data-cy="footer-nav"
        >
          {Structure.footer.menu
            .filter(({hideForBarmej}) => !hideForBarmej || PARTNER !== 'BARMEJ')
            .map(({url, text, title, isExternal}, i) => {
              return (
                <li className="dib pv2" key={i}>
                  {isExternal ? (
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={title}
                      className="gray dim pointer"
                    >
                      {text}
                    </a>
                  ) : (
                    <Link to={url} title={title} className="gray dim pointer">
                      {text}
                    </Link>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}
