import React from 'react';

class Certificate extends React.Component {
  static defaultProps = {
    width: '36px',
    height: '30px',
    color: '#31aa69',
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="2 15 36 5">
        <path
          fill={this.props.color}
          d="M4.87 16.14l5.84 1.95v6.51a.58.58 0 0 0 0 .26c.06.16 1.66 3.95 9.07 3.95v-1.4c-5.79 0-7.43-2.43-7.72-3v-5.85l7.5 2.5a.86.86 0 0 0 .45 0L23.34 20v.56a2.11 2.11 0 0 0-1.34 1.8l-1.4 7 1.38.28L23 24.31a1.58 1.58 0 0 0 .35.16v5.05h1.41v-5.05a1.58 1.58 0 0 0 .35-.16l1.07 5.35 1.37-.28-1.4-7a2.1 2.1 0 0 0-1.39-1.84v-1l2.81-.93v6.53a2.93 2.93 0 0 1-.46.47l.89 1.02a3.54 3.54 0 0 0 .88-1 .71.71 0 0 0 .12-.33v-7.21l5.84-1.95a.7.7 0 0 0 0-1.33L20.06 9.89a.69.69 0 0 0-.45 0L4.87 14.81a.7.7 0 0 0 0 1.33zm19.18 7.06a.71.71 0 1 1 .7-.7.71.71 0 0 1-.7.7zm-4.22-11.9l12.53 4.18L24.69 18a.69.69 0 0 0-.28-.35l-2.59-1.55a1.82 1.82 0 0 0 .12-.65 2.11 2.11 0 1 0-2.11 2.1 2.07 2.07 0 0 0 1.17-.3l2.17 1.3-3.3 1.1-12.56-4.17zm.71 4.18a.71.71 0 1 1-.71-.71.71.71 0 0 1 .71.71z"
        />
      </svg>
    );
  }
}

export default Certificate;
