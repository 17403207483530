import React, {Component} from 'react';
import {NavFooter, SocialMedia, CopyRights} from '../Menus';

export default class FooterComponent extends Component {
  render() {
    return (
      <footer className="w-100">
        <div className="ph2 ph0-ns center">
          <div className="cf pv3 w-80-ns w-90-m w-90 center">
            <NavFooter />
            <SocialMedia />
          </div>
        </div>
        <CopyRights />
      </footer>
    );
  }
}
