import React from 'react';

class Course extends React.Component {
  static defaultProps = {
    width: '17px',
    height: '17px',
    color: '#5C9CE4',
  };

  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg">
        <g fill={this.props.color}>
          <path d="M2.125 6.709h4.958v1.385H2.125zM2.125 9.478h4.958v1.385H2.125z" />
          <path d="M0 14.325h9.208v3.196l2.125-1.039 2.125 1.039v-3.196H17V.478H0v13.847zm12.042.956l-.709-.347-.708.347v-.956h1.417v.956zm3.541-2.341H9.208V1.863h6.375V12.94zM1.417 1.863h6.375V12.94H1.417V1.863z" />
          <path d="M9.917 3.94h4.958v1.385H9.917zM9.917 6.709h4.958v1.385H9.917zM9.917 9.478h4.958v1.385H9.917z" />
        </g>
      </svg>
    );
  }
}

export default Course;
