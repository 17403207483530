import React from 'react';

class Referrals extends React.Component {
  static defaultProps = {
    width: '32px',
    height: '26px',
    color: '#31aa69',
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 64 64"
      >
        <g
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeWidth="3"
          transform="translate(0.5 0.5)"
          fill={this.props.color}
          stroke={this.props.color}
        >
          <path
            data-cap="butt"
            fill="none"
            stroke={this.props.color}
            strokeMiterlimit="10"
            d="M41.697,41.336 C38.01,40.559,33.46,40,28,40c-11.14,0-18.494,2.331-22.453,4.087C3.379,45.048,2,47.196,2,49.567V60h32"
            strokeLinecap="butt"
          />
          <path
            fill="none"
            stroke={this.props.color}
            strokeMiterlimit="10"
            d="M14,16 c0-7.732,6.268-14,14-14s14,6.268,14,14s-6.268,16-14,16S14,23.732,14,16z"
          />
          <line
            data-color="color-2"
            fill="none"
            strokeMiterlimit="10"
            x1="50"
            y1="46"
            x2="50"
            y2="54"
          />
          <line
            data-color="color-2"
            fill="none"
            strokeMiterlimit="10"
            x1="46"
            y1="50"
            x2="54"
            y2="50"
          />
          <circle
            data-color="color-2"
            fill="none"
            strokeMiterlimit="10"
            cx="50"
            cy="50"
            r="12"
          />
        </g>
      </svg>
    );
  }
}

export default Referrals;
