import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import Structure from 'core/settings/structure';
import {activeLink, currentLink, midScreen} from './styles.scss';

const {PARTNER} = process.env;

class NavMenu extends Component {
  render() {
    const {className, authenticated} = this.props;
    return (
      <nav className={`dn db-ns h-100 b ${className}`}>
        <ul className="h-100 flex items-center list ma0 pa0" data-cy="nav-menu">
          {Structure.header.navMenu
            .filter(({hideAnonymous}) => authenticated || !hideAnonymous)
            .filter(({displayAnonymous}) => !authenticated || !displayAnonymous)
            .filter(
              ({hideForPartner}) => !hideForPartner || PARTNER === 'BARMEJ',
            )
            .map(({path, text, title, isExternal}, i) => {
              return (
                <li className="h-100 tc" href="/good" key={i}>
                  {isExternal ? (
                    <a
                      href={path}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={`h-100 link white db flex items-center ${midScreen} ${activeLink}`}
                    >
                      {text}
                    </a>
                  ) : (
                    <Link
                      to={path}
                      title={title}
                      className={`h-100 link white db flex items-center ${midScreen} ${activeLink}`}
                      activeClassName={currentLink}
                    >
                      {text}
                    </Link>
                  )}
                </li>
              );
            })}
        </ul>
      </nav>
    );
  }
}

NavMenu.propTypes = {
  className: PropTypes.string,
  authenticated: PropTypes.bool.isRequired,
};

export default NavMenu;
