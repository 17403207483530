import auth from 'modules/auth/reducer';
import codechallenges from 'modules/codechallenges/reducer';
import leaderboard from 'modules/leaderboard/reducer';
import notifications from 'modules/notifications/reducer';
import profiles from 'modules/profiles/reducer';
import quizzes from 'modules/quizzes/reducer';
import tracks from 'modules/tracks/reducer';
import user from 'modules/user/reducer';
import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import theme from 'theme/reducer';
import entities from './api/reducer';
import {loadingBarReducer} from './libs/loadingBar';
import logger from './libs/logger/reducer';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['authenticated', 'key', 'provider'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['profile', 'progress', 'selectedPlan', 'loginProvider'],
};

const appReducer = combineReducers({
  entities,
  loadingBar: loadingBarReducer,
  logger,
  theme,
  auth: persistReducer(authPersistConfig, auth),
  codechallenges,
  leaderboard,
  notifications,
  profiles,
  quizzes,
  tracks,
  user: persistReducer(userPersistConfig, user),
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT_COMPLETE' || action.type === 'SIGN_OUT_ERROR') {
    storage.removeItem('persist:auth');
    storage.removeItem('persist:user');
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
