/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Structure from 'core/settings/structure';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {menuStyle} from './styles.scss';
import SubMenu from './SubMenu';

const DropDownMenu = ({handleClick, user, handleSignOut, loginProvider}) => (
  <div
    className={`z-999 fixed dn db-ns absolute-ns ${menuStyle} left-0 ba br3 bg-white b--light-gray mt0-ns mt0-m`}
  >
    <div className="flex flex-column" onClick={handleClick} data-cy="user-menu">
      <Link
        className="pa3 dim black-90 tc tr-ns bb b--light-gray"
        to={`/user/${user.slug}`}
      >
        <FormattedMessage id="view_profile" />
      </Link>
      <SubMenu items={Structure.header.userMenu} loginProvider={loginProvider} />
      <a
        className="pa3 dim black-90 tc tr-ns pointer"
        data-cy="signout"
        onClick={handleSignOut}
      >
        <FormattedMessage id="log_out" />
      </a>
    </div>
  </div>
);

DropDownMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  loginProvider: PropTypes.string,
};

export default DropDownMenu;
