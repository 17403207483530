import cx from 'classnames';
import formattedRelativeTime from 'core/libs/helpers/formattedRelativeTime';
import {Link} from 'gatsby';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import NotificationIcons from 'modules/common/Svg/NotificationIcons';
import PropTypes from 'prop-types';
import React from 'react';
import Emoji from 'react-emoji-render';
import {FormattedMessage} from 'react-intl';
import {compose, lifecycle} from 'recompose';
import NotificationLink from './NotificationLink';
import {
  customBlue,
  customBorderColor,
  darkGray,
  heightNotification,
  heightSeeAll,
  marginButton,
  marginNotification,
  notificationWidth,
  up,
} from './styles.scss';

const NotificationMenu = ({
  notificationsData,
  handleClick,
  slug,
  resultsData,
}) => (
  <div
    className={cx('db-ns dn', {pointer: resultsData.length > 0})}
    onKeyPress={handleClick}
    onClick={handleClick}
  >
    <div className={`${up} fixed absolute-ns`} />
    <div
      className={`z-999 fixed absolute-ns center-h-absolute ml5 flex flex-column ph4 ${marginNotification} ${darkGray} ba br3 ${notificationWidth} b--transparent`}
    >
      {notificationsData.status !== 'fetched' ? (
        <LoadingIndicator />
      ) : (
        <>
          {resultsData.length > 0 ? (
            <>
              {resultsData
                .slice(0, 5)
                .map(
                  ({title, created_at, announcement_type, url, label}, i) => (
                    <NotificationLink
                      url={
                        label === 'school-track-completed' ||
                        label === 'school-degree-completed' ||
                        label === 'school-course-completed'
                          ? `/user/${slug}`
                          : url
                      }
                      key={i}
                    >
                      <div
                        className={`flex justify-between pointer dim bb items-center ${customBorderColor} ${heightNotification}`}
                      >
                        <div className="flex justify-between">
                          <div className="pr3">
                            <NotificationIcons
                              announcementType={announcement_type}
                              color="#203147"
                              notificationType={label}
                            />{' '}
                          </div>
                          <div className="ph3">
                            <div className="flex justify-between">
                              <h4 className="white b f6">
                                <Emoji
                                  text={
                                    title.length > 29
                                      ? `${title.slice(0, 27)}...`
                                      : title
                                  }
                                />
                              </h4>
                            </div>
                            <p className="light-silver mt1 f7">
                              {' '}
                              {formattedRelativeTime(created_at)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </NotificationLink>
                  ),
                )}
              <div className={`${heightSeeAll} tc`}>
                <Link
                  to="/notifications"
                  className={`dim no-underline ${customBlue} dib ml2-ns ${marginButton} mh1 ph3 ph3-ns f6`}
                  data-cy="see-all-notifications"
                >
                  <FormattedMessage id="see_all_notifications" />
                </Link>
              </div>
            </>
          ) : (
            <p className="pv3 white tc">
              <FormattedMessage id="there_is_no_notifications_now" />
            </p>
          )}
        </>
      )}
    </div>
  </div>
);

NotificationMenu.propTypes = {
  notificationsData: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  resultsData: PropTypes.array.isRequired,
};

const enhance = compose(
  lifecycle({
    componentDidMount() {
      const {forceGetNotifications} = this.props;
      forceGetNotifications();
    },
  }),
);

export default enhance(NotificationMenu);
