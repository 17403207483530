import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

const {PARTNER} = process.env;

const SubMenu = ({className, items, isMobile, loginProvider}) => (
  <div className={`flex flex-column ${className}`}>
    {items
      .filter(({hideForPartner}) => !hideForPartner || (PARTNER === 'BARMEJ' && loginProvider !== 'futurex'))
      .map(({text, path, isExternal}, i) => {
        return isExternal ? (
          <a
            href={path}
            key={i}
            rel="noopener noreferrer"
            target="_blank"
            className={`pa3 dim ${
              isMobile ? 'white' : 'black-90'
            } tc tr-ns bb b--light-gray`}
          >
            {text}
          </a>
        ) : (
          <Link
            key={i}
            className={`pa3 dim ${
              isMobile ? 'white' : 'black-90'
            } tc tr-ns bb b--light-gray`}
            to={path}
          >
            {text}
          </Link>
        );
      })}
  </div>
);

SubMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  loginProvider: PropTypes.string,
};

export default SubMenu;
