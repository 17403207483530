import React from 'react';
import PropTypes from 'prop-types';
import HamburgerButton from 'modules/common/HamburgerButton';
import UserAvatar from 'modules/common/UserAvatar';
import caret from 'theme/assets/images/arrow-down.svg';

const UserMenuButton = ({onClick, showMenu, user}) => {
  return (
    <div className="pr3-l pr2 flex items-center justify-around">
      <div
        data-cy="user-menu-btn"
        className="dn flex-ns items-center justify-around pointer pr3-l pr2"
        onClick={onClick}
        onKeyPress={onClick}
      >
        <img src={caret} alt="caret" />
        <span className="fw9 pr1 dn-m" data-cy="header-user-full-name">
          {user.first_name} {user.last_name}
        </span>
        <UserAvatar className="mr3" photo={user.avatar_url} />
      </div>
      <HamburgerButton active={showMenu} className="dn-ns" onClick={onClick} />
    </div>
  );
};

UserMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserMenuButton;
