export const showLoadingBar = () => ({
  type: 'SHOW_LOADING_BAR',
});

export const hideLoadingBar = () => ({
  type: 'HIDE_LOADING_BAR',
});

export const resetLoadingBar = () => ({
  type: 'RESET_LOADING_BAR',
});
