import React from 'react';

class Project extends React.Component {
  static defaultProps = {
    width: '17px',
    height: '17px',
    color: '#62CA92',
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 48 48"
      >
        <g
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          fill={this.props.color}
          stroke={this.props.color}
        >
          <path
            fill="none"
            stroke={this.props.color}
            strokeMiterlimit="10"
            d="M46,21v20 c0,2.209-1.791,4-4,4H6c-2.209,0-4-1.791-4-4V3h16l6,8h6"
          />{' '}
          <polygon
            data-color="color-2"
            fill="none"
            strokeMiterlimit="10"
            points=" 31,27 26,28 27,23 41,9 45,13 "
          />
        </g>
      </svg>
    );
  }
}

export default Project;
