import React from 'react';

class Reminder extends React.Component {
  static defaultProps = {
    width: '20px',
    height: '20px',
    color: '#e50000',
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 48 48"
      >
        <path
          fill={this.props.color}
          d="M6,47c-0.51172,0-1.02393-0.19531-1.41406-0.58594c-0.78125-0.78076-0.78125-2.04736,0-2.82812 l5.27295-5.27295c0.78027-0.78125,2.04785-0.78125,2.82812,0c0.78125,0.78076,0.78125,2.04736,0,2.82812l-5.27295,5.27295 C7.02393,46.80469,6.51172,47,6,47z"
        />{' '}
        <path
          fill={this.props.color}
          d="M42,47c-0.51172,0-1.02393-0.19531-1.41406-0.58594l-5.27295-5.27295 c-0.78125-0.78076-0.78125-2.04736,0-2.82812c0.78027-0.78125,2.04785-0.78125,2.82812,0l5.27295,5.27295 c0.78125,0.78076,0.78125,2.04736,0,2.82812C43.02393,46.80469,42.51172,47,42,47z"
        />{' '}
        <circle fill="#FFFFFF" cx="24" cy="27" r="18" />{' '}
        <path
          fill={this.props.color}
          d="M24,47C12.97217,47,4,38.02783,4,27S12.97217,7,24,7s20,8.97217,20,20S35.02783,47,24,47z M24,11 c-8.82227,0-16,7.17773-16,16s7.17773,16,16,16s16-7.17773,16-16S32.82227,11,24,11z"
        />{' '}
        <path
          fill="#E86C60"
          d="M17,35c-0.25586,0-0.51172-0.09766-0.70703-0.29297c-0.39062-0.39062-0.39062-1.02344,0-1.41406l7-7 c0.39062-0.39062,1.02344-0.39062,1.41406,0s0.39062,1.02344,0,1.41406l-7,7C17.51172,34.90234,17.25586,35,17,35z"
        />{' '}
        <path
          fill="#223E49"
          d="M24,28c-0.55273,0-1-0.44775-1-1V17c0-0.55225,0.44727-1,1-1s1,0.44775,1,1v10 C25,27.55225,24.55273,28,24,28z"
        />{' '}
        <path
          fill="#223E49"
          d="M34,28H24c-0.55273,0-1-0.44775-1-1s0.44727-1,1-1h10c0.55273,0,1,0.44775,1,1S34.55273,28,34,28z"
        />{' '}
        <circle fill="#223E49" cx="24" cy="27" r="3" />{' '}
        <path
          fill={this.props.color}
          d="M17.95904,4.04808c0.49165-0.3998,0.49499-1.15546,0.00519-1.55752 c-3.958-3.24896-9.78013-3.3827-13.90722-0.02665s-5.18334,9.08313-2.80972,13.62046 c0.29374,0.56149,1.0342,0.71231,1.52585,0.31252L17.95904,4.04808z"
        />{' '}
        <path
          fill={this.props.color}
          d="M30.04096,4.04808c-0.49165-0.3998-0.49499-1.15546-0.00519-1.55752 c3.958-3.24896,9.78014-3.3827,13.90722-0.02665s5.18334,9.08313,2.80972,13.62046 c-0.29374,0.56149-1.03421,0.71231-1.52585,0.31252L30.04096,4.04808z"
        />
      </svg>
    );
  }
}

export default Reminder;
