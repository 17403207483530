import {combineReducers} from 'redux';

const invoiceID = (state = {data: {}, status: 'not fetched'}, action) => {
  switch (action.type) {
    case 'GET_INVOICE_ID_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_INVOICE_ID_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_INVOICE_ID_FAILURE':
      return {
        ...state,
        data: action.error.response && action.error.response.data,
        status: 'rejected',
      };
    case 'RESET_PAYMENT_DATA':
      return {data: {}, status: 'not fetched'};
    default:
      return state;
  }
};

const paymentURL = (state = {data: {}, status: 'not fetched'}, action) => {
  switch (action.type) {
    case 'GET_PAYMENT_URL_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_PAYMENT_URL_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'GET_PAYMENT_URL_FAILURE':
      return {
        ...state,
        data: action.error.response && action.error.response.data,
        status: 'rejected',
      };
    case 'RESET_PAYMENT_DATA':
      return {data: {}, status: 'not fetched'};
    default:
      return state;
  }
};

export default combineReducers({
  invoiceID,
  paymentURL,
});
