import {signOut} from 'modules/auth/actions/sign-out';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose';
import DropDownMenu from './DropDownMenu';
import MobileMenu from './MobileMenu';
import UserMenuButton from './UserMenuButton';

const NavProfile = ({
  className = '',
  user,
  toggleShowMenu,
  showMenu,
  dispatch,
  registerNode,
  authenticated,
  availableRedeemPoints,
  dividedBy,
  loginProvider,
}) => {
  const isMobile = window.innerWidth <= 630;
  return (
    <div className={`relative flex h-100 ${className}`} ref={registerNode}>
      <UserMenuButton
        onClick={toggleShowMenu}
        showMenu={showMenu}
        scoreUser={user.total_score ? user.total_score : 0}
        user={user}
      />
      {showMenu && (
        <DropDownMenu
          handleClick={toggleShowMenu}
          user={user}
          handleSignOut={() => dispatch(signOut())}
          loginProvider={loginProvider}
        />
      )}
      {showMenu && isMobile && (
        <MobileMenu
          handleSignOut={() => dispatch(signOut())}
          handleClick={toggleShowMenu}
          user={user}
          authenticated={authenticated}
          availableRedeemPoints={availableRedeemPoints}
          dividedBy={dividedBy}
          loginProvider={loginProvider}
        />
      )}
    </div>
  );
};

NavProfile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  toggleShowMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  registerNode: PropTypes.func.isRequired,
  authenticated: PropTypes.bool,
  availableRedeemPoints: PropTypes.number,
  dividedBy: PropTypes.string.isRequired,
  loginProvider: PropTypes.string,
};

const mapStateToProps = ({user}) => ({
  user: user.profile,
  loginProvider: user.loginProvider.provider,
});

const enhance = compose(
  connect(mapStateToProps),

  withState('showMenu', 'setShowMenu', false),

  withHandlers(() => {
    let node;
    return {
      registerNode: () => (ref) => {
        node = ref;
      },
      getNode: () => () => {
        return node;
      },
      toggleShowMenu: ({setShowMenu, showMenu}) => () => {
        setShowMenu(!showMenu);
      },
    };
  }),

  lifecycle({
    componentDidMount() {
      const node = this.props.getNode();
      const handleOutsideClick = (e) => {
        if (!node) return;
        const {toggleShowMenu, showMenu} = this.props;
        // ignore clicks on the component itself
        if (showMenu && !node.contains(e.target)) {
          toggleShowMenu();
        }
      };
      document.addEventListener('click', handleOutsideClick.bind(this), false);
    },
  }),

  withPropsOnChange(['user'], ({user}) => ({
    user: user.data,
  })),
);

export default enhance(NavProfile);
