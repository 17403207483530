import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  activeStyle,
  bottomActive,
  buttonHeight,
  top05,
  topActive,
  widthHamburger,
} from './styles.scss';

const HamburgerButton = ({active, className, onClick}) => (
  <button
    aria-label="Hamburger button"
    className={cx(
      'relative pointer white bg-transparent bn outline-0',
      className,
      buttonHeight,
      widthHamburger,
    )}
    onClick={onClick}
  >
    <span
      className={cx('absolute left-0 w-100 bt bw2 transition', {
        [activeStyle]: active,
        [topActive]: active,
        'top-0': !active,
      })}
    />
    <span
      className={cx('absolute left-0 w-100 bt bw2 transition', top05, {
        'o-0': active,
      })}
    />
    <span
      className={cx('absolute left-0 w-100 bt bw2 transition', {
        [activeStyle]: active,
        [bottomActive]: active,
        'top-1': !active,
      })}
    />
  </button>
);

HamburgerButton.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default HamburgerButton;
