import Avatar from 'modules/common/Svg/Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {fit} from './styles.scss';

const UserAvatar = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {className = '', photo} = props;
  return photo ? (
    <img
      width="50"
      height="50"
      alt={formatMessage({id: 'label_photo'})}
      className={`br-100 ${className} ${fit}`}
      src={photo}
    />
  ) : (
    <Avatar />
  );
};

UserAvatar.propTypes = {
  className: PropTypes.string,
  photo: PropTypes.string,
};

export default UserAvatar;
