/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */

import {applyMiddleware, createStore, compose} from 'redux';
import {createLogger} from 'redux-logger';
import {persistStore} from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from 'core/reducers';
import callAPIMiddleware from 'core/api/callAPIMiddleware';
import {fetchUser} from 'modules/user/actions/profile';
import {getProgress} from 'modules/user/actions/progress';

let middleware = applyMiddleware(callAPIMiddleware, thunk);
let composeEnhancers = compose;

if (
  (process.env.NODE_ENV !== 'production' || process.env.GATSBY_DEBUG == 1) && // eslint-disable-line eqeqeq
  typeof window === 'object' && // We don't want logger at build time
  process.env.NODE_ENV !== 'test'
) {
  const logger = createLogger({
    collapsed: (getState, action) =>
      action.type.search(/LOADING_BAR/) !== -1 ||
      action.type.search(/REQUEST/) !== -1,
  });
  middleware = applyMiddleware(callAPIMiddleware, thunk, logger);
  if (
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
}

const store = createStore(rootReducer, composeEnhancers(middleware));

const afterRehydrate = () => {
  if (typeof window !== 'undefined') {
    store.dispatch(fetchUser());
    store.dispatch(getProgress());
  }
};

export const persistor =
  typeof window !== 'undefined'
    ? persistStore(store, null, afterRehydrate)
    : undefined;

export default store;
