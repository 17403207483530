import React from 'react';
import PropTypes from 'prop-types';

const Close = props => {
  const {
    bgColor = '#19a974',
    className,
    color = '#FFFFFF',
    height = '40px',
    width = '40px',
  } = props;
  return (
    <svg
      version="1.1"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30.2 30.2">
      <circle fill={bgColor} cx="12.6" cy="12.6" r="12.6" />
      <rect
        x="5.4"
        y="11.6"
        transform="matrix(0.7068 -0.7074 0.7074 0.7068 -5.0947 12.447)"
        fill={color}
        width="14.1"
        height="1.6"
      />
      <rect
        x="11.7"
        y="5.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.0948 12.4403)"
        fill={color}
        width="1.6"
        height="14.1"
      />
    </svg>
  );
};
Close.propTypes = {
  bgColor: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Close;
