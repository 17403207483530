import PropTypes from 'prop-types';
import React from 'react';
import Link from 'modules/common/Link';

const NewDesignButton = ({
  active = true,
  children,
  colored,
  className = '',
  color,
  link,
  onClick,
  style: propsStyle = {},
  external = false,
  attrs = {},
  border = true,
  cy,
  externalLink,
  state,
}) => {
  const style = {};
  if (colored) {
    style.backgroundColor = active ? color : '#ddd';
  }
  if (color) {
    style.borderColor = active ? color : '#ddd';
  }

  let additionalClasses = className;
  if (active && (link || onClick !== undefined)) {
    additionalClasses += ' pointer';
  }
  if (colored) {
    additionalClasses += ' white pv2 fw6 ph2 b--white';
  } else {
    additionalClasses += ' ph2 dark-gray br2';
  }

  if (border) {
    additionalClasses += ' bw1';
  }

  return external ? (
    <a
      href={link}
      onClick={(e) => {
        if (typeof onClick === 'function') {
          if (active) {
            onClick(e);
          }
        }
      }}
      target="_blank"
      rel="noopener noreferrer"
      className={`fw6 white pv2 dib no-underline tc br2 ${additionalClasses}`}
      style={{...style, ...propsStyle}}
      {...attrs}
      data-cy={cy}
    >
      {children}
    </a>
  ) : (
    <Link
      to={link}
      href={externalLink}
      className={`fw6 white pv2 dib no-underline tc br2 ${additionalClasses}`}
      onClick={(e) => {
        if (typeof onClick === 'function') {
          if (active) {
            onClick(e);
          }
        }
      }}
      style={{...style, ...propsStyle}}
      {...attrs}
      data-cy={cy}
      state = { state }
    >
      {children}
    </Link>
  );
};

NewDesignButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  colored: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  external: PropTypes.bool,
  attrs: PropTypes.object,
  border: PropTypes.bool,
  cy: PropTypes.string,
  externalLink: PropTypes.string,
};

export default NewDesignButton;
