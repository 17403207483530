const InitialState = {
  authenticated: false,
  cookies: {},
  error: undefined,
  loading: false,
  urlParams: {},
};

const reducer = (state = InitialState, action = {}) => {
  switch (action.type) {
    case 'SIGN_IN_START':
    case 'SIGN_OUT_START':
    case 'SIGN_UP_START':
    case 'CHECK_MAIL_START':
    case 'SEND_VERIFICATION_EMAIL_START':
    case 'SOCIAL_SIGN_IN_REQUEST':
      return {...state, error: undefined, loading: true};
    case 'SIGN_IN_COMPLETE':
    case 'SOCIAL_SIGN_IN_SUCCESS':
      return {
        ...state,
        loading: false,
        authenticated: true,
        key: action.key || action.response.data.key,
        provider: action.provider,
        isSignUp: state.isSignUp || action.response.data.is_new,
      };
    case 'SIGN_OUT_COMPLETE':
    case 'SIGN_OUT_ERROR':
      return {...state, loading: false, authenticated: false, key: undefined};
    case 'SIGN_UP_COMPLETE':
      return {
        ...state,
        loading: false,
        isSignUp: true,
      };
    case 'CHECK_MAIL_COMPLETE':
    case 'SEND_VERIFICATION_EMAIL_COMPLETE':
      return {
        ...state,
        loading: false,
      };
    case 'SIGN_IN_ERROR':
    case 'SIGN_UP_ERROR':
    case 'CHECK_MAIL_ERROR':
    case 'SEND_VERIFICATION_EMAIL_ERROR':
    case 'SOCIAL_SIGN_IN_FAILURE':
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case 'LOAD_COOKIE_SUCCESS': {
      return {
        ...state,
        cookies: {
          ...state.cookies,
          [action.name]: action.value,
        },
      };
    }
    case 'COOKIE_REMOVE_FINISHED':
      return {
        ...state,
        cookies: {
          ...state.cookies,
          [action.name]: undefined,
        },
      };
    case 'RECORD_URL_PARAMS':
      return {
        ...state,
        urlParams: {
          ...state.urlParams,
          ...action.params,
        },
      };
    default:
      return state;
  }
};

export default reducer;
