import isRequired from './isRequired';

function timeSince(date = isRequired('date', 'timeSince')) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return 'سنة';
    }
    if (Math.floor(interval) === 2) {
      return 'سنتين';
    }
    if (Math.floor(interval) > 2 && Math.floor(interval) < 11) {
      return Math.floor(interval) + ' سنوات';
    }
    return Math.floor(interval) + ' سنة';
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return 'شهر';
    }
    if (Math.floor(interval) === 2) {
      return 'شهرين';
    }
    if (Math.floor(interval) > 2 && Math.floor(interval) < 11) {
      return Math.floor(interval) + ' أشهر';
    }
    return Math.floor(interval) + ' شهر';
  }

  interval = seconds / 86400;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return 'يوم';
    }
    if (Math.floor(interval) === 2) {
      return 'يومين';
    }
    if (Math.floor(interval) > 2 && Math.floor(interval) < 11) {
      return Math.floor(interval) + ' أيام';
    }
    return Math.floor(interval) + ' يوم';
  }

  interval = seconds / 3600;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return 'ساعة';
    }
    if (Math.floor(interval) === 2) {
      return 'ساعتين';
    }
    if (Math.floor(interval) > 2 && Math.floor(interval) < 11) {
      return Math.floor(interval) + ' ساعات';
    }
    return Math.floor(interval) + ' ساعة';
  }

  interval = seconds / 60;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return 'دقيقة';
    }
    if (Math.floor(interval) === 2) {
      return 'دقيقتان';
    }
    if (Math.floor(interval) > 2 && Math.floor(interval) < 11) {
      return Math.floor(interval) + ' دقائق';
    }
    return Math.floor(interval) + ' دقيقة';
  }

  if (Math.floor(seconds) === 1) {
    return 'ثانية';
  }
  if (Math.floor(seconds) === 2) {
    return 'ثانيتين';
  }
  if (Math.floor(seconds) > 2 && Math.floor(seconds) < 11) {
    return Math.floor(seconds) + ' ثواني';
  }
  return Math.floor(seconds) + ' ثانية';
}

export default timeSince;
