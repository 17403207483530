const InitialState = {
  isFetching: false,
  fetched: false,
  rejected: false,
  error: null,
  data: {},
};

const profile = (state = InitialState, action) => {
  switch (action.type) {
    case 'GET_PROFILE_REQUEST':
    case 'UPDATE_USER_PROFILE_REQUEST':
    case 'UPDATE_HIDE_RECOMMENDED_TRACKS_REQUEST':
      return {...state, isFetching: true};
    case 'GET_PROFILE_SUCCESS':
    case 'UPDATE_USER_PROFILE_SUCCESS':
    case 'UPDATE_HIDE_RECOMMENDED_TRACKS_SUCCESS':
      return {
        ...state,
        data: {
          ...action.response.data,
          // flatten social_links for redux-form
          social_links: undefined,
          ...action.response.data.social_links.reduce(
            (acc, {link, provider}) => {
              acc[provider] = link; // eslint-disable-line no-param-reassign
              return acc;
            },
            {},
          ),
        },
        isFetching: false,
        fetched: true,
      };
    case 'GET_PROFILE_FAILURE':
    case 'UPDATE_USER_PROFILE_FAILURE':
    case 'UPDATE_HIDE_RECOMMENDED_TRACKS_FAILURE':
      return {
        ...state,
        isFetching: false,
      };
    case 'PHONE_CHECK_CODE_SUCCESS': {
      return {
        ...state,
        data: {
          ...state.data,
          phone_verified: true,
        },
      };
    }
    default:
      return state;
  }
};

export default profile;
