import React from 'react';
import {FormattedMessage} from 'react-intl';
import {toast} from 'react-toastify';

function notification({id, message, type}) {
  let notify = '';

  if (type === 'success') {
    notify = toast.success(id ? <FormattedMessage id={id} /> : message);
  } else {
    notify = toast.error(id ? <FormattedMessage id={id} /> : message);
  }

  return notify;
}

export default notification;
