import Portals from 'modules/common/Portals';
import PropTypes from 'prop-types';
import React from 'react';
import closeIcon from 'theme/assets/images/cancel.svg';
import {content, customBtn, innerContent} from './styles.scss';

const Popup = ({exitPopup, hideCloseIcon, children}) => (
  <Portals>
    <div className="flex fixed top-0 left-0 bottom-0 right-0 w-100 h-100 justify-center bg-black-40 z-999">
      <div // eslint-disable-line jsx-a11y/click-events-have-key-events
        className="fixed w-100 h-100 top-0 right-0 left-0 bottom-0 bg-black-40"
        onClick={exitPopup}
      />
      <div className={`self-center relative ${content}`}>
        <div className="tc bg-white">
          {!hideCloseIcon && (
            <div className="tr">
              <button
                aria-label="Close popup"
                className={`bn pointer ${customBtn}`}
                type="button"
                onClick={exitPopup}
              >
                <img src={closeIcon} alt="close popup" />
              </button>
            </div>
          )}
          <div className={innerContent}>{children}</div>
        </div>
      </div>
    </div>
  </Portals>
);

Popup.propTypes = {
  exitPopup: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Popup;
