import React from 'react';
import PropTypes from 'prop-types';
import Help from 'modules/common/Svg/Help';
import SupportWidget from './SupportWidget';

class ToggleSupportWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSupportWidget: false,
    };
  }

  toggleSupportWidget = () => {
    this.setState((state) => ({showSupportWidget: !state.showSupportWidget}));
  };

  render() {
    return (
      <div>
        {this.state.showSupportWidget && (
          <SupportWidget
            toggleModalWidget={this.toggleSupportWidget}
            authenticated={this.props.authenticated}
          />
        )}
        {this.state.showSupportWidget ? (
          ''
        ) : (
          // eslint-disable-next-line react/button-has-type
          <button
            aria-label="Support widget"
            className="z-9999 sans-serif b--none pointer br-pill bg-blue bg-animate hover-bg-dark-blue white f4 fw6 ph3 pv2 fixed mb3 ml3 left-0 bottom-0"
            onClick={() => {
              this.toggleSupportWidget();
            }}
          >
            <div className="flex">
              <Help />
            </div>
          </button>
        )}
      </div>
    );
  }
}

ToggleSupportWidget.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

export default ToggleSupportWidget;
