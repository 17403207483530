import axios from 'axios';
import {showLoadingBar, hideLoadingBar} from 'core/libs/loadingBar';

const API = process.env.GATSBY_API;

const callAPIMiddleware = ({dispatch, getState}) => {
  return (next) => (action) => {
    const {
      types, // example: ['LOAD_POSTS_REQUEST', 'LOAD_POSTS_SUCCESS', 'LOAD_POSTS_FAILURE'],
      config = {}, // { method, url, data: {}} https://github.com/mzabriskie/axios#axiosconfig
      shouldCallAPI = () => true, // Function that tell if we should do the fetch or no
      payload = {}, // Data to be passed to redux with the 3 actions
      callback, // callback function, will get true as argument true and response if the call passed and false and error if the call failed
      loadingBar = true,
    } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every((type) => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof config.url !== 'string') {
      throw new Error('Expected config.url to be a string');
    }

    // If there is no http(s) it is a relative path to API url
    let url = /https?:\/\//.test(config.url)
      ? config.url
      : `${API}${config.url}`;

    const state = getState();

    const apikey = state.auth.key;
    if (apikey && url.includes(API)) {
      if (url.includes('?')) {
        url = `${url}&apikey=${apikey}`;
      } else {
        url = `${url}?apikey=${apikey}`;
      }
    }

    if (!shouldCallAPI(state)) {
      return false;
    }

    const [requestType, successType, failureType] = types;

    if (loadingBar) dispatch(showLoadingBar());
    dispatch({
      ...payload,
      type: requestType,
    });

    const finalConfig = {
      ...config,
      url,
      headers: {
        ...config.headers,
        'Accept-Language': 'ar',
      },
    };
    return axios(finalConfig)
      .then((response) => {
        if (loadingBar) dispatch(hideLoadingBar());
        dispatch({
          ...payload,
          response,
          type: successType,
        });
        if (typeof callback === 'function') callback(true, response);
        return response;
      })
      .catch((error) => {
        if (loadingBar) dispatch(hideLoadingBar());
        // const response = error.response || {};
        // log(`${response.status || ''} ${error.message} on ${config.url}`, {
        //   endpoint: config.url,
        //   error_message: error.message,
        //   method: config.method || 'get',
        //   ...response,
        //   // We don't want config as it contains sensitive data
        //   config: undefined,
        //   request: undefined,
        // });
        dispatch({
          ...payload,
          error,
          type: failureType,
        });
        if (typeof callback === 'function') callback(false, error);
      });
  };
};

export default callAPIMiddleware;
