import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {clearDiv, loadingBarStyle} from './styles.scss';

export const UPDATE_TIME = 100;
export const MAX_PROGRESS = 100;
export const PROGRESS_INCREASE = 10;
export const ANIMATION_TIME = UPDATE_TIME * 4;

export class LoadingBar extends React.Component {
  state = {percent: 0};
  UNSAFE_componentWillMount() {
    const {loading} = this.props;
    if (loading > 0) this.launch();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {loading} = this.props;

    if (loading === 0 && nextProps.loading > 0) {
      this.launch();
    } else if (this.progressInterval && nextProps.loading === 0) {
      this.setState({percent: 100});
    }
  }
  componentWillUnmount() {
    clearInterval(this.progressInterval);
    clearTimeout(this.endingAnimationTimeout);
  }
  launch = () => {
    const {updateTime} = this.props;
    if (!this.progressInterval) {
      this.progressInterval = setInterval(this.simulateProgress, updateTime);
    }
    this.setState({
      percent: 0,
    });
  };
  newPercent() {
    const {progressIncrease} = this.props;
    const {percent} = this.state;
    // Use cos as a smoothing function
    const smoothedProgressIncrease =
      progressIncrease * Math.cos(percent * (Math.PI / 2 / 100));
    return percent + smoothedProgressIncrease;
  }
  simulateProgress = () => {
    const {maxProgress} = this.props;
    const {percent} = this.state;

    if (percent === 100) {
      // Hiding the bar:
      clearInterval(this.progressInterval);
      this.progressInterval = null;
      this.endingAnimationTimeout = setTimeout(() => {
        this.setState({percent: 0});
      }, ANIMATION_TIME);
    } else if (this.newPercent() <= maxProgress) {
      this.setState({percent: this.newPercent()});
    }
  };
  buildStyle = () => {
    const {style} = this.props;
    const {percent} = this.state;
    return {
      opacity: percent > 0 && percent <= 100 ? '1' : '0',
      transform: `scaleX(${percent / 100})`,
      transition: `transform ${ANIMATION_TIME}ms linear`,
      ...style,
    };
  };
  render() {
    const {className} = this.props;
    return (
      <div>
        <div
          className={`${loadingBarStyle} ${className}`}
          style={this.buildStyle()}
        />
        <div className={clearDiv} />
      </div>
    );
  }
}
LoadingBar.propTypes = {
  className: PropTypes.string.isRequired,
  loading: PropTypes.number.isRequired,
  maxProgress: PropTypes.number.isRequired,
  progressIncrease: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  updateTime: PropTypes.number.isRequired,
};
LoadingBar.defaultProps = {
  className: '',
  loading: 0,
  maxProgress: MAX_PROGRESS,
  progressIncrease: PROGRESS_INCREASE,
  showFastActions: true,
  style: {},
  updateTime: UPDATE_TIME,
};

export default connect(({loadingBar}) => ({
  loading: loadingBar,
}))(LoadingBar);
