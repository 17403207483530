import React from 'react';
import PropTypes from 'prop-types';

const FieldWrapperWithLabel = ({children, htmlFor, label}) => {
  if (label)
    return (
      <div key={htmlFor} className="mt4-ns mt3">
        <div className="flex-ns mt2">
          <label htmlFor={htmlFor} className="mt4 w-30-ns db b f6 pb3 pb0-ns">
            {label}
          </label>
          <div className="relative flex1">{children}</div>
        </div>
      </div>
    );
  return children;
};

FieldWrapperWithLabel.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
};

export default FieldWrapperWithLabel;
