import React from 'react';
import {useIntl} from 'react-intl';
import facebookicon from '../../../assets/images/socialmedia/facebook.svg';
import githubicon from '../../../assets/images/socialmedia/github.svg';
import instagramicon from '../../../assets/images/socialmedia/instagram.svg';
import linkedinicon from '../../../assets/images/socialmedia/linkedin.svg';
import twittericon from '../../../assets/images/socialmedia/twitter.svg';

const {PARTNER} = process.env;

const SocialMedia = () => {
  const intl = useIntl();
  const {formatMessage} = intl;
  return (
    <div className="fr w-100 w-40-m w-30-ns tc tl-ns">
      <div className="flex items-center flex-row-reverse-ns justify-start-ns justify-center">
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              PARTNER === 'BARMEJ'
                ? 'https://www.instagram.com/barmej/'
                : 'https://www.instagram.com/joincoded/'
            }
            className="w2 link pointer dim dib mr1"
            data-cy="instagram"
            title={formatMessage({id: 'instagram'})}
          >
            <img src={instagramicon} alt={formatMessage({id: 'instagram'})} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              PARTNER === 'BARMEJ'
                ? 'https://twitter.com/Barmej'
                : 'https://twitter.com/joincoded'
            }
            className="w2 link pointer dim dib mh1"
            data-cy="twitter"
            title={formatMessage({id: 'label_twitter'})}
          >
            <img src={twittericon} alt={formatMessage({id: 'label_twitter'})} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              PARTNER === 'BARMEJ'
                ? 'https://www.facebook.com/barmej'
                : 'https://www.facebook.com/joincoded'
            }
            className="w2 link pointer dim ml1 mr1-ns mr0-m mr0 dib"
            data-cy="facebook"
            title={formatMessage({id: 'label_facebook'})}
          >
            <img
              src={facebookicon}
              alt={formatMessage({id: 'label_facebook'})}
            />
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              PARTNER === 'BARMEJ'
                ? 'https://github.com/barmej'
                : 'https://github.com/kuwaitcodes'
            }
            className="w2 link pointer dim dib mr1 ml1-ns ml0-m ml0"
            data-cy="github"
            title={formatMessage({id: 'label_github'})}
          >
            <img src={githubicon} alt={formatMessage({id: 'label_github'})} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              PARTNER === 'BARMEJ'
                ? 'https://www.linkedin.com/company/barmej'
                : 'https://www.linkedin.com/company/coded./'
            }
            className="link pointer dim dib mh1"
            data-cy="linkedin"
            title={formatMessage({id: 'label_linkedin'})}
          >
            <img
              className="pa1 br-100 bg-light-silver"
              src={linkedinicon}
              alt={formatMessage({id: 'label_linkedin'})}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
