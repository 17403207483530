/* eslint-disable react/state-in-constructor */
import Notification from 'modules/common/Svg/Notification';
import NotificationMenu from 'modules/notifications/components/NotificationMenu';
import withNotifications from 'modules/notifications/withNotifications';
import PropTypes from 'prop-types';
import React from 'react';
import {badge} from './styles.scss';

class NotificationUser extends React.Component {
  state = {
    showNotificationMenu: false,
  };

  componentDidMount() {
    const {getNotifications} = this.props;
    this.forceGetNotifications = () => getNotifications({force: true});
    this.notificationInterval = setInterval(this.forceGetNotifications, 300000);
    getNotifications();
  }

  componentWillUnmount() {
    clearInterval(this.notificationInterval);
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleClick = () => {
    const {showNotificationMenu} = this.state;
    if (showNotificationMenu) this.closeNotificationMenu();
    else this.openNotificationMenu();
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (!this.node || this.node.contains(e.target)) {
      return;
    }
    this.closeNotificationMenu();
  };

  openNotificationMenu = () => {
    const {updateLastSeenDate} = this.props;
    document.addEventListener('click', this.handleOutsideClick, false);
    this.setState({showNotificationMenu: true});
    updateLastSeenDate();
  };

  closeNotificationMenu = () => {
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({showNotificationMenu: false});
  };

  render() {
    const {
      className = '',
      notificationsData,
      notificationCount,
      slug,
      resultsData,
    } = this.props;
    const {showNotificationMenu} = this.state;

    return (
      <div
        className={`relative dn flex-ns items-center ${className}`}
        ref={(node) => {
          this.node = node;
        }}
      >
        <div
          className="pointer grow"
          onKeyPress={this.handleClick}
          onClick={this.handleClick}
          data-cy="notifications-icon"
        >
          {notificationCount > 0 && (
            <div className={badge}>{notificationCount}</div>
          )}
          <Notification />
        </div>
        {notificationsData.data.results && showNotificationMenu && (
          <NotificationMenu
            forceGetNotifications={this.forceGetNotifications}
            handleClick={this.closeNotificationMenu}
            notificationsData={notificationsData}
            slug={slug}
            resultsData={resultsData}
          />
        )}
      </div>
    );
  }
}

NotificationUser.propTypes = {
  className: PropTypes.string,
  getNotifications: PropTypes.func.isRequired,
  notificationsData: PropTypes.objectOf(PropTypes.any),
  notificationCount: PropTypes.number.isRequired,
  updateLastSeenDate: PropTypes.func.isRequired,
  slug: PropTypes.string,
  resultsData: PropTypes.array,
};

export default withNotifications(NotificationUser);
