import {findSiteData} from 'core/libs/helpers/findSiteData';
import React from 'react';
import {FormattedMessage} from 'react-intl';

const currentYear = new Date().getFullYear();

const CopyRights = () => (
  <div className="w-100 bg-light-gray gray">
    <div className="ph2 ph0-ns w-80-ns w-90 center pv3">
      <div className="flex justify-between items-center flex-column flex-row-ns">
        <p className="tr-ns tc f5">
          <FormattedMessage
            id="copyrights"
            defaultMessage="All rights reserved.Coded.ltd."
          />
          &nbsp; {currentYear}
        </p>
        <p className="tl-ns tc f5">
          {findSiteData('copyRight')} {currentYear}
        </p>
      </div>
    </div>
  </div>
);

export default CopyRights;
