export const loadingBarReducer = (state = 0, action) => {
  switch (action.type) {
    case 'SHOW_LOADING_BAR':
      return state + 1;
    case 'HIDE_LOADING_BAR':
      // Why do we need this ?
      return state === 0 ? 0 : state - 1;
    case 'RESET_LOADING_BAR':
      return 0;
    default:
      return state;
  }
};
export default loadingBarReducer;
