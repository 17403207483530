import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import {
  dlgBox,
  dlgBoxMedium,
  dlgBoxLarge,
  dlgBoxAchievements,
  whiteTooltip,
  blackTooltip,
  bottomBox,
  smallBottomBox,
  bottomBoxPointSystemDiscount,
} from './styles.scss';

const Tooltip = ({
  children,
  message,
  large,
  achievement,
  className = '',
  bottomBlack,
  bottomWhite,
  smallBottomWhite,
  isPointSystemDiscount,
}) => (
  <div className={cx('relative dib pr2', dlgBox)}>
    {children}
    <div
      className={cx('f13 light-silver tc absolute', className, {
        [dlgBoxLarge]: large,
        [dlgBoxMedium]: !large,
        [dlgBoxAchievements]: achievement,
        [blackTooltip]: bottomBlack,
        [whiteTooltip]: !bottomBlack,
        [bottomBox]: bottomWhite,
        [`${smallBottomBox} db-ns dn`]: smallBottomWhite,
        [bottomBoxPointSystemDiscount]: isPointSystemDiscount,
      })}
    >
      {message}
    </div>
  </div>
);
Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  large: PropTypes.bool,
  achievement: PropTypes.bool,
  className: PropTypes.string,
  bottomBlack: PropTypes.bool,
  bottomWhite: PropTypes.bool,
  smallBottomWhite: PropTypes.bool,
  isPointSystemDiscount: PropTypes.bool,
};

export default Tooltip;
