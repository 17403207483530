const codechallenges = (state = {data: {}, status: 'not fetched'}, action) => {
  switch (action.type) {
    case 'CHECK_CODECHALLENGE_REQUEST':
      return {
        timestamp: undefined,
      };
    case 'CHECK_CODECHALLENGE_SUCCESS':
      return {
        timestamp: action.currentTime,
      };
    default:
      return state;
  }
};

export default codechallenges;
