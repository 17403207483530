import {combineReducers} from 'redux';

const availableCertificates = (
  state = {data: [], status: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'GET_ELIGIBLE_CERTIFICATES_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'GET_ELIGIBLE_CERTIFICATES_SUCCESS':
      return {
        ...state,
        requested_coupon_code: action.coupon_code,
        data: (action.response.data && action.response.data.eligible_for) || [],
        status: 'fetched',
      };
    case 'GET_ELIGIBLE_CERTIFICATES_FAILURE':
      return {
        ...state,
        error:
          action.error && action.error.response && action.error.response.data,
        status: 'rejected',
      };
    default:
      return state;
  }
};

const certificateCreation = (
  state = {data: {}, status: 'not fetched'},
  action,
) => {
  switch (action.type) {
    case 'CREATE_CERTIFICATE_REQUEST':
      return {
        ...state,
        status: 'fetching',
      };
    case 'CREATE_CERTIFICATE_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        status: 'fetched',
      };
    case 'CREATE_CERTIFICATE_FAILURE':
      return {
        ...state,
        data: action.error.response && action.error.response.data,
        status: 'rejected',
      };
    case 'RESET_PAYMENT_DATA':
      return {data: {}, status: 'not fetched'};
    default:
      return state;
  }
};

export default combineReducers({
  availableCertificates,
  certificateCreation,
});
