import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({className = '', height = '43px', width = '43px'}) => (
  <svg
    version="1.1"
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 59.2 59.2">
    <circle fill="#F1F1F1" cx="29.6" cy="29.6" r="29.6" />
    <path
      fill="#B9BCBF"
      d="M29.6,23.1c-5.3,0-9.7,4.3-9.7,9.7c0,1.4,1.1,2.5,2.5,2.5h14.3c1.4,0,2.5-1.1,2.5-2.5C39.3,27.4,35,23.1,29.6,23.1L29.6,23.1z"
    />
    <path
      fill="#B9BCBF"
      d="M29.6,13c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S32.1,13,29.6,13L29.6,13z"
    />
  </svg>
);

Avatar.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Avatar;
