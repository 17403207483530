import translations from 'data/translations.json';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import PropTypes from 'prop-types';
import React from 'react';
import {IntlProvider} from 'react-intl';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Theme from 'theme';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import GET_MY_SUBSCRIPTIONS from 'modules/common/graphql/subscriptions.graphql';

class Layout extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    if (global.Intl) {
      this.state = {
        loading: false,
      };
    } else {
      // Load Intl polyfill for browsers that don't support
      this.state = {
        loading: true,
      };
      import('intl').then((intl) => {
        global.Intl = intl;
        this.setState({loading: false});
      });
    }
  }

  componentDidUpdate() {
    const {authenticated, isPaidUser} = this.props;

    if (authenticated) {
      if (isPaidUser !== undefined) {
        const userType = isPaidUser ? 'Paid' : 'Free';
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer.push({
            userType,
            event: 'UserType',
          });
        }
      }
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />;
    }
    return (
      <IntlProvider locale="ar-u-nu-latn" messages={translations}>
        <Theme>
          <ToastContainer position="top-center" rtl />
          {this.props.children}
        </Theme>
      </IntlProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  authenticated: PropTypes.bool.isRequired,
  isPaidUser: PropTypes.bool,
};

const enhance = compose(
  connect(({auth}) => ({
    authenticated: auth.authenticated,
  })),
  // graphql(GET_MY_SUBSCRIPTIONS, {
  //   skip: ({authenticated}) => !authenticated,
  //   props: ({data}) => {
  //     const {error, loading, me} = data;
  //     if (loading) return {loading};
  //     if (error) return {error, loading: false};
  //     const isPaidUser = me && me.isPaidUser;

  //     return {
  //       isPaidUser,
  //     };
  //   },
  // }),
);

export default enhance(Layout);
