import React from 'react';

class BadgeEarned extends React.Component {
  static defaultProps = {
    width: '36px',
    height: '30px',
    color: '#31aa69',
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="2 15 36 5">
        <polygon
          fill="#687179"
          points="19.83 22.22 17.07 22.22 17.07 29.43 19.83 27.46 19.83 22.22"
        />
        <polygon
          fill="#42474b"
          points="19.83 22.22 22.6 22.22 22.6 29.43 19.83 27.46 19.83 22.22"
        />
        <path
          fill={this.props.color}
          d="M19.11,10.58a.93.93,0,0,1,1.45,0c.63.78.85.82,1.74.4a.93.93,0,0,1,1.31.63c.23,1,.4,1.1,1.4,1.11a.94.94,0,0,1,.91,1.14c-.21,1-.12,1.17.78,1.61A.94.94,0,0,1,27,16.89c-.62.78-.62,1,0,1.79a.93.93,0,0,1-.32,1.41c-.9.44-1,.64-.78,1.61A.94.94,0,0,1,25,22.84c-.8,0-1.12-.05-1.4,1.12a.94.94,0,0,1-1.31.63c-.72-.34-1-.53-1.74.4a.93.93,0,0,1-1.45,0c-.64-.78-.85-.82-1.75-.4A.94.94,0,0,1,16.05,24c-.23-1-.38-1.11-1.4-1.12a.93.93,0,0,1-.9-1.14c.21-1,.12-1.16-.78-1.61a.93.93,0,0,1-.32-1.41c.61-.79.61-1,0-1.79A.94.94,0,0,1,13,15.47c.89-.44,1-.63.78-1.61a.94.94,0,0,1,.9-1.14c1,0,1.17-.16,1.4-1.11A.93.93,0,0,1,17.36,11C18.25,11.39,18.47,11.36,19.11,10.58Z"
        />
        <circle fill={this.props.color} cx="19.83" cy="17.78" r="5.07" />
      </svg>
    );
  }
}

export default BadgeEarned;
