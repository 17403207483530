/* eslint-disable no-lonely-if */
// eslint-disable-next-line import/no-cycle
import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';
// eslint-disable-next-line import/no-cycle
import {signOut} from 'modules/auth/actions/sign-out';

export const fetchUser = ({isSignIn, isSignUp, provider, force} = {}) => (
  dispatch,
) => {
  const {PARTNER} = process.env;
  const loginEvent = PARTNER === 'BARMEJ' ? 'Login' : `${PARTNER} login`;
  const completeRegistrationEvent =
    PARTNER === 'BARMEJ'
      ? 'CompleteRegistration'
      : `${PARTNER}CompleteRegistration`;

  dispatch({
    types: [
      'GET_PROFILE_REQUEST',
      'GET_PROFILE_SUCCESS',
      'GET_PROFILE_FAILURE',
    ],
    config: {
      url: 'users/current/',
    },
    callback: (success, result) => {
      if (success) {
        if (isSignIn) {
          if (window && window.mixpanel) {
            window.mixpanel.identify(result.data.id);
            window.mixpanel.track(loginEvent, {provider});
          }

          if (typeof window !== 'undefined') {
            if (window.dataLayer) {
              window.dataLayer.push({
                loginEvent,
                provider: `web app sign-in using the provider : ${provider}`,
                event: 'Submitted Web App Sign-in Form Successfully',
              });
            }
          }
          if (!result.data.is_verified) {
            navigate('/auth/verify-email');
            notification({id: 'verification_email_sent_success'});
          }
        }
        if (isSignUp) {
          if (process.env.NODE_ENV === 'production') {
            if (window.fbq) {
              window.fbq('init', '817230048400285', {
                email: result.data.email,
                first_name: result.data.first_name,
              });
              window.fbq('track', completeRegistrationEvent);
            }
          }
        }
      } else if (result.response && result.response.status === 403) {
        dispatch(signOut());
        notification({id: 'session_expired'});
      } else {
        notification({id: 'data_error'});
      }
    },
    shouldCallAPI: ({
      auth,
      user: {
        profile: {status},
      },
    }) =>
      auth.authenticated &&
      (force || (status !== 'fetched' && status !== 'fetching')),
  });
};

export default fetchUser;

export const updateUserProfile = (values, options = {}) => (dispatch) => {
  const {hasNotif = true} = options;
  const data = new FormData();
  [
    'about',
    'first_name',
    'is_new',
    'is_onboarding',
    'hide_recommended_tracks',
    'last_name',
    'nationality',
    'slug',
    'url',
    'tap_information',
  ].forEach((field) => {
    if (values[field] !== undefined) data.append(field, values[field]);
  });

  if (
    values.photo &&
    typeof values.photo !== 'string' &&
    values.photo !== null
  ) {
    data.append('photo', values.photo);
  }
  if (values.phone)
    data.append('phone', values.phone.length < 5 ? null : values.phone);
  if (values.selected_tracks)
    data.append('selected_tracks', JSON.stringify(values.selected_tracks));

  const providers = ['FACEBOOK', 'GITHUB', 'LINKEDIN', 'TWITTER'];
  const social_links = [];
  providers.forEach((provider) => {
    const value = values[provider];
    if (value && value.trim())
      social_links.push({
        link: value,
        provider,
      });
  });
  if (social_links.length)
    data.append('social_links', JSON.stringify(social_links));

  dispatch({
    types: [
      'UPDATE_USER_PROFILE_REQUEST',
      'UPDATE_USER_PROFILE_SUCCESS',
      'UPDATE_USER_PROFILE_FAILURE',
    ],
    config: {
      url: 'users/current/',
      method: 'PATCH',
      data,
    },
    callback: (success, response) => {
      if (success) {
        if (hasNotif) {
          notification({
            id: 'success_update_profile',
            type: 'success',
          });
        }
      } else {
        if (response && response.response) {
          const notificationMessage = Object.values(response.response.data)
            .map((fieldError) =>
              fieldError.map((value) =>
                typeof value === 'object' ? Object.values(value) : value,
              ),
            )
            .toString();
          notification({message: notificationMessage});
        }
      }
    },
  });
};
