import {combineReducers} from 'redux';
import certificates from './reducers/certificates';
import payment from './reducers/payment';
import profile from './reducers/profile';
import progress from './reducers/progress';
import notifications from './reducers/notifications';
import referrals from '../common/ReferralFriends/reducer';
import accessToken from './reducers/disqus-token';
import selectedPlan from './reducers/selected-plan';
import loginProvider from './reducers/login-provider';

export default combineReducers({
  certificates,
  payment,
  progress,
  profile,
  notifications,
  referrals,
  accessToken,
  selectedPlan,
  loginProvider,
});
