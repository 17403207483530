const loginProvider = (state = {provider: null}, action) => {
  switch (action.type) {
  case 'SAVE_LOGIN_PROVIDER':
      return {
        ...state,
        provider: action.payload.provider,
      };

  default:
    return state;
  }

};

export default loginProvider;
